import { _ } from "./underscore";
import moment from 'moment';

const ABBR_NUM_ORDER = [
  {num:1E15, den:"P"},
  {num:1E12, den:"T"},
  {num:1E9, den:"B"},
  {num:1E6, den:"M"},
  {num:1E3, den:"K"},
];


const ABBR_DATA_SIZE_ORDER = [
  {num:1E15, den:"PB"},
  {num:1E12, den:"TB"},
  {num:1E9, den:"GB"},
  {num:1E6, den:"MB"},
  {num:1E3, den:"KB"},
];

const DAY_MS = 86400000;
const WEEK_MS = DAY_MS * 7;
const MONTHS = [
  {name:"January", short:"Jan"},
  {name:"February", short:"Feb"},
  {name:"March", short:"Mar"},
  {name:"April", short:"Apr"},
  {name:"May", short:"May"},
  {name:"June", short:"June"},
  {name:"July", short:"Jul"},
  {name:"August", short:"Aug"},
  {name:"September", short:"Sep"},
  {name:"October", short:"Oct"},
  {name:"November", short:"Nov"},
  {name:"December", short:"Dec"}
];

const DAYS = [
  {name:"Sunday", short:"Sun"},
  {name:"Monday", short:"Mon"},
  {name:"Tuesday", short:"Tue"},
  {name:"Wednesday", short:"Wed"},
  {name:"Thursday", short:"Thu"},
  {name:"Friday", short:"Fri"},
  {name:"Saturday", short:"Sat"}
];

const MOMENT_FORMATS = {
  sameDay: 'h:mm a zz',
  nextDay: '[Tomorrow]',
  nextWeek: '[Next] dddd',
  lastDay: '[Yesterday]',
  lastWeek: 'dddd',
  sameElse: 'MMM DD, YYYY'
};

/**
 * Converts a percentage (0-100) to a degree rotation value
 */
export const financial = function(value, {currency='$', commas=true, compact=true}={}) {
  let fixed = (Number.parseFloat(value) || 0.0).toFixed(2);
  if (commas) {
    let numParts = fixed.split(".");
    numParts[0] = commafy(parseInt(numParts[0]) || 0);
    fixed = numParts.join(".");
  }
  if (compact) {
    fixed = fixed.replace(/\.0+$/,"");
  }
  return currency ? currency+fixed : fixed;
};


/**
 * Converts a percentage (0-100) to a degree rotation value
 */
export const money = function(value, {fix=2}={}) {
  if (value === null || value === undefined) { return value; }
  return parseFloat(value).toFixed(fix);
};

/**
 * Converts the number into a string and adds commas
 */
export const commafy = function(num) {
  return num.toLocaleString('en', {useGrouping:true})
}


/**
 * Abbreviates the number and appends denomiation
 */
export const abbr = function(num=0, {fix=null, prefix="", type="number", den=""}={}) {
  if (num === 0) { return `${prefix}${num}`; }
  let abs = Math.abs(num);
  let abbrOrder = ABBR_NUM_ORDER;
  switch (type) {
    case "data":
      abbrOrder = ABBR_DATA_SIZE_ORDER
      break;
  }
  let sign = num >= 0 ? "" : "-";
  let quot = abs;
  if (abs >= 1000) {
    for (var i = 0; i < abbrOrder.length; i++) {
      let order = abbrOrder[i];
      if (abs >= order.num) {
        quot = (abs / order.num);
        if (fix===null) {
          fix = (quot > 100) ? 0 : (quot > 10) ? 1 : 2;
        }
        den = order.den;
        break;
      }
    }
  }
  return sign + prefix + quot.toFixed(fix).replace(/(^0+|\.0+)$/, '') + den;
}


/**
 * Converts the number into a string and adds commas
 */
export const capitalize = function(str) {
  return str && str.length > 0 ? str[0].toUpperCase() + str.substring(1) : str;
}


/**
 * Datify
 * ref: https://momentjs.com/docs/#/displaying/format/
 */
export const datify = function(date) {
  return moment.utc(date).local().calendar(null, MOMENT_FORMATS);
}

/**
 * parsedate
 * ref: https://momentjs.com/docs
 */
export const parsedate = function(date) {
  return moment.utc(date).local();
}

/**
 * sqldate
 * ref: https://momentjs.com/docs/#/displaying/format/
 */
export const sqldate = function(date, format="YYYY-MM-DD") {
  return moment.utc(date).format(format);
}

/**
 * plaindate
 * ref: https://momentjs.com/docs/#/displaying/format/
 */
export const plaindate = function(date, format="YYYY-MM-DD") {
  return moment(date).format(format);
}

/**
 * Trims the pre and post whitespace from the string
**/
export const trim = function(str) {
  if (!_.isString(str)) { return str; }
  return str.replace(/^\s+|\s+$/g,'');
}


/**
 * Checks to see if the string is an email
**/
export const isValidEmail = function(str) {
  if (!str || !_.isString(str) ) { return false; }
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

/**
 * Parses the query string to a hash map
**/
export const parseQuery = function(str) {
  let map = {};
  if (!_.isString(str)) { return map; }
  str = str.replace(/^\?/,'');
  let terms = str.split('&');
  terms.forEach((term)=>{
    let parts = term.split('=');
    map[parts[0]] = decodeURIComponent(parts[1]);
  });
  return map;
}

/**
 * Stringifies query string to a hash map
**/
export const stringifyQuery = function(map = {}) {
  let keys = Object.keys(map)
  let terms = [];
  keys.forEach((key)=>{
    let val = map[key];
    if (val !== null && val !== undefined) {
      terms.push(`${key}=${encodeURIComponent(map[key])}`);
    }
  });
  return terms.join('&');
}

/**
 * Calculate the number of days since epoc
**/
export const epocdays = function(date) {
  date = date || new Date();
  return date.getTime() / DAY_MS;
}

/**
 * Off set the date wit hte current amount of days
**/
export const dayoffset = function(days=0, date=null) {
  date = date || new Date();
  return new Date(date.getTime()+(days*DAY_MS));
}


/**
 * Stringifies the float to give just enough detail
**/
export const percentify = function(f = 0, fbk) {
  if (fbk && (f===null || f===undefined)) { return fbk; }
  if (f===0) { return "0%"; }
  f = f * 100;
  if (f >= 10 )  { return f.toFixed(0)+"%"; }
  if (f >= 1 )   { return f.toFixed(1)+"%"; }
  if (f >= 0.1 ) { return f.toFixed(2)+"%"; }
  return f.toFixed(3)+"%";
}

/**
 * Stringifies the date to give just enough detail
**/
export const simplifyDate = function(str) {
  let date = moment(str);
  return date.toString().split('GMT')[0].replace(/^\w+\s/,'');
}

/*
 Calculates the percentage of amount to total
*/
export const pcnt = function(amount, total) {
  if (amount == null || total == null) { return 0; }
  if (total === 0) { return 0; }
  return Math.round((amount / total) * 100);
}