import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import RectButton from '../elements/RectButton';

import TableSearchSection from '../wrappers/TableSearchSection';

import { startDraftItem, purgeItem, pushItem, setItemField } from '../../actions/InventoryActions';
import { dot, deepCopy, mapk2k, mappify, sfc } from '../../lib/obj';

import './Page.css';
import './SearchPage.scss';
import './BuildsPage.scss';

import { BUILD_SEARCH, BUILD_STATUS } from '../../constants/inventory/Build';

const DEFAULT_BUILD_DATA = {
  status: BUILD_STATUS[0].value
};

class BuildsPage extends Page {

  constructor() {
    super();
    this.state.notes = {};
  }

  onAdd(evt) {
    this.setState({sci: null, notes: {}});
    this.props.startDraftItem({
      type: "builds",
      index: -1,
      data: deepCopy(DEFAULT_BUILD_DATA)
    });
  }

  onView(evt, history, item) {
    if (item) {
      history.push(`/builds/${item.data.id}`);
    }
  }

  render() {
    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add Build</RectButton>;
    return (
      <div className="Page SearchPage BuildsPage">
        <PageHeaderOutline title={this.props.title} aside={addButton} top={this._backButton()}></PageHeaderOutline>
        <TableSearchSection label="Builds" className="Build" controllable="true"
        type="builds"
        query={BUILD_SEARCH}
        reload={true}
        onAddItem={(f)=>{ this.onAdd() }}
        layout={[
          {title:"Created",     field:"createdAt", type:"date" },
          {title:"ID",          field:"id", type: "action", onClick:(e,h,i)=>{this.onView(e,h,i)}},
          {title:"Name",        field:"name", type: "string" },
          {title:"Status",      field:"fulfillmentStatus", type: "dropdown", options: BUILD_STATUS },
          {title:"Completed",   field:"completedAt", type:"date" },
          {title:" ",           field:"notes",  type: "text", className:"Description"},
        ]}/>
      </div>
    )
  }
}

const mapState = (state, props) => {
  let buildsList = dot(state.inventory,"builds.list") || {};
  return {
    buildsList: buildsList,
    builds: buildsList.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    purgeItem: opts => dispatch(purgeItem(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
  }
};

export default connect(
  mapState,
  mapDispatch
)(BuildsPage)
