import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import PaddedArea from '../areas/PaddedArea';
import Section from '../elements/Section';

import QueryLine from '../layouts/QueryLine';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openPopup, closePopup } from '../../actions/PopupActions';
import { startDraftItem, purgeDraftItem, setItemField } from '../../actions/InventoryActions';
import { loadItem, meldItems, queryItems, setItemQuery, pushItem } from '../../actions/InventoryActions';
import { dot, reg, deepCopy, mapk2k, peg } from '../../lib/obj';
import { configToNestedQuery } from '../../lib/inventory-util';

import { _ } from '../../lib/underscore';
import { percentify, commafy } from '../../lib/formats';
import { queryToWhere } from '../../lib/inventory-util';

import './Page.css';
import './SearchPage.scss';
import './ReturnStatsPage.scss';
import  '../../lib/date-util';

import { RETURN_SEARCH, RETURN_BASE_SEARCH, RETURN_STATES, RETURN_STATES_MAP, RETURN_REASONS,
  RETURN_REASONS_MAP, RETURN_FULFILLMENT, RETURN_FULFILLMENT_MAP, RETURN_CARRIERS,
  RETURN_CARRIERS_MAP, RETURN_RESTOCKED, RETURN_RESTOCKED_MAP,
  RETURN_RESOLUTIONS, RETURN_RESOLUTIONS_MAP } from '../../constants/inventory/OrderReturn'

  const CUSTOM_RETURN_SEARCH = deepCopy(RETURN_BASE_SEARCH);

const TYPE_NAME = "order-returns";
const TYPE_ALIAS = "order-return-stats";
const TYPE_FULL = "order-return-full";
const TYPE_REASON = "order-return-reasons";
const TYPE_DESCRIPTION = "order-return-descriptions";
const TYPE_FULFILLMENT = "order-return-fulfillments";
const TYPE_RESTOCK = "order-return-restocks";
const TYPE_RESOLUTION = "order-return-resolutions";
const TYPE_STATUS = "order-return-statuses";
const TYPE_SKUS = "order-return-skus";
const TYPE_REFUNDS = "order-return-refunds";
const TYPE_SALES = "product-sales";

const BOOL_YN_MAP = {
  true: "Yes",
  false: "No"
}

export const ORDER_ITEM_SEARCH = {
  type: "order-items",
  links: ["order","item"]
}

class ReturnStatsPage extends Page {

  constructor() {
    super();
    this.state.expanded = false;
  }

  componentWillMount() {
    let createdFilter = CUSTOM_RETURN_SEARCH.filters.find(f=>f.field=="createdAt");
    createdFilter.options[2].selected = true; // Last 7 days


    let props = this.props;
    let baseConfig = deepCopy(CUSTOM_RETURN_SEARCH);
    props.setItemQuery({
      type: "order-returns",
      typeAlias: TYPE_ALIAS,
      query: baseConfig
    });

    // Load stats
    this.loadStatsWithBaseConfig(CUSTOM_RETURN_SEARCH);
  }

  // Get the query config
  onConfigChange(config, throttle) {
    // Load stats
    this.loadStatsWithBaseConfig(config, throttle);
  }

  loadStatsWithBaseConfig(config, throttle) {

    // Base conditions 
    let conditions = [{deleted:{neq:1}},{resolution:{neq:"expired"}}];
    const epoc = Date.now();

    // Create the base period query 
    let periodQuery = configToNestedQuery(deepCopy(config), deepCopy(conditions));
    let createdCondition = periodQuery.where.find(c=>c["createdAt"]); 
    let completedConditon = periodQuery.where.find(c=>c["completedAt"]); 
    let rangeCondition = createdCondition ? createdCondition["createdAt"] : null;
    if (!rangeCondition) {
      rangeCondition = completedConditon ? completedConditon["completedAt"] : null;
    }

    // Handle to all the meld calls 
    const calls = []; 

    // Build the stats queries
    let fullConfig = deepCopy(CUSTOM_RETURN_SEARCH);
    let fullConditions = rangeCondition ? deepCopy(conditions).concat([{"createdAt": rangeCondition}]) : deepCopy(conditions);
    let fullQ = configToNestedQuery(fullConfig, fullConditions);
    fullQ.fields = ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku"];
    fullQ.calcs  = ["sum.quantity"];
    fullQ.group  = ["itemId"];
    calls.push(this.props.meldItems({
      query: fullQ,
      type: "order-returns",
      typeAlias: TYPE_FULL,
      epoc: epoc
    }));

    // Build the stats queries
    let skuQ = deepCopy(periodQuery);
    skuQ.fields = ["id","bookId","createdAt","itemId"];
    skuQ.calcs  = ["sum.quantity"];
    skuQ.group  = ["itemId"];
    calls.push(this.props.meldItems({
      query: skuQ,
      type: "order-returns",
      typeAlias: TYPE_SKUS,
      epoc: epoc
    }));

    // Build the stats queries
    let refundedConditions = [{or:[{resolution:{eq:"refund"}},{resolution:{eq:"partial-refund"}}]}];
    let refundedQ = configToNestedQuery(deepCopy(config), deepCopy(conditions).concat(refundedConditions));
    refundedQ.fields = ["id","bookId","createdAt","itemId"];
    refundedQ.calcs  = ["sum.quantity"];
    calls.push(this.props.meldItems({
      query: refundedQ,
      type: "order-returns",
      typeAlias: TYPE_REFUNDS,
      epoc: epoc
    }));

    let reasonsQ = deepCopy(periodQuery);
    reasonsQ.fields = ["id","bookId","createdAt","reason"];
    reasonsQ.calcs  = ["sum.quantity"];
    reasonsQ.group  = ["reason"];
    calls.push(this.props.meldItems({
      query: reasonsQ,
      type: "order-returns",
      typeAlias: TYPE_REASON,
      epoc: epoc
    }));

    let descriptionQ = deepCopy(periodQuery);
    descriptionQ.fields = ["id","bookId","createdAt","description"];
    descriptionQ.calcs  = ["sum.quantity"];
    descriptionQ.group  = ["description"];
    calls.push(this.props.meldItems({
      query: descriptionQ,
      type: "order-returns",
      typeAlias: TYPE_DESCRIPTION,
      epoc: epoc
    }));

    let statusQ = deepCopy(periodQuery);
    statusQ.fields = ["id","bookId","createdAt","status"];
    statusQ.calcs  = ["sum.quantity"];
    statusQ.group  = ["status"];
    calls.push(this.props.meldItems({
      query: statusQ,
      type: "order-returns",
      typeAlias: TYPE_STATUS,
      epoc: epoc
    }));

    let restockedQ = deepCopy(periodQuery);
    restockedQ.fields = ["id","bookId","createdAt","restocked"];
    restockedQ.calcs  = ["sum.quantity"];
    restockedQ.group  = ["restocked"];
    calls.push(this.props.meldItems({
      query: restockedQ,
      type: "order-returns",
      typeAlias: TYPE_RESTOCK,
      epoc: epoc
    }));

    let resolutionQ = deepCopy(periodQuery);
    resolutionQ.fields = ["id","bookId","createdAt","resolution"];
    resolutionQ.calcs  = ["sum.quantity"];
    resolutionQ.group  = ["resolution"];
    calls.push(this.props.meldItems({
      query: resolutionQ,
      type: "order-returns",
      typeAlias: TYPE_RESOLUTION,
      epoc: epoc
    }));

    let salesConfig = deepCopy(ORDER_ITEM_SEARCH);
    let salesRangeCondition = rangeCondition ? {"mainOrder.createdAt":rangeCondition} : null;
    let salesQ = configToNestedQuery(salesConfig, [{deleted:{neq:1}}, salesRangeCondition]);
    salesQ.fields = ["id","bookId","orderId","itemId","item.sku","item.id","item.bookId","order.id","order.createdAt","order.bookId"];
    salesQ.calcs  = ["sum.quantity"];
    salesQ.group  = ["item.id"];
    calls.push(this.props.meldItems({
      query: salesQ,
      type: "order-items",
      typeAlias: TYPE_SALES,
      epoc: epoc
    }));

    Promise.allSettled(calls).then((results) => {
      if (throttle) { 
        console.log("Throttle done");
        setTimeout(()=>{throttle.done();},100)
      }
      console.log('All promises have settled:', epoc);
    });
  }

  onExpand(evt, history) {
    let expanded = !this.state.expanded;
    if (this.props.onExpand) {
      this.props.onExpand(expanded);
    }
    this.setState({expanded:expanded});
  }

  genMeticDiv(name, count, key="foo") {
    let status = this.props.skusList.status || "loading";
    return <table className="Card StickyHeader" key={`metrics${key}`} animate={status}>
      <thead>
        <tr key="metrics-1">
          <th className="Name">{name}</th>
        </tr>
      </thead>
      <tbody>
        <tr key="metrics-1">
          <td className="Count">{count}</td>
        </tr>
      </tbody>
    </table>
  }

  renderMetrics() {
    let tskus = this.props.fullSkuQty;
    let tsales = this.props.totalSales;
    let trefunds = this.props.totalRefunds;
    return (<div className="StatTables MetricStats">
      {this.genMeticDiv("Total Returns", commafy(tskus), "TR")}
      {this.genMeticDiv("Total Sold", commafy(tsales), "TS")}
      {this.genMeticDiv("Total Returns Rate", tsales > 0 ? percentify(tskus/tsales) : "-", "TRR")}
      {this.genMeticDiv("Total Refunds", commafy(trefunds), "TRF")}
      {this.genMeticDiv("Total Refunds Rate", tsales > 0 ? percentify(trefunds/tsales) : "-", "TRFR")}
    </div>);
  }

  genSortProps(table,col) {
    let srt = this.state[`sort${table}`] || {};
    return {
      onClick: ()=>{this.toggleSort(table,col)},
      'data-sort': srt.col == col ? (srt.asc ? 'asc' : 'desc') : undefined
    }
  }

  toggleSort(table, col) {
    let handle = `sort${table}`;
    let srt = this.state[handle] || {};
    if (srt.col == col) {
      this.setState({[handle]:{col:col, asc:!srt.asc}});
    }
    else {
      this.setState({[handle]:{col:col, asc:true}});
    }
  }

  sortDataArray(arr, col, asc = true) {
    arr.sort((a,b)=>{
      if (a[col] < b[col]) {
        return asc ? -1 : 1;
      }
      else if ((a[col] > b[col])) {
        return asc ? 1 : -1;
      }
      return 0;
    });
  }

  renderSkuStats() {
    let stats = this.props.skus;
    let salesBySku = this.props.salesBySku;
    let status = this.props.skusList.status || "loading";
    let total = 0;
    let totalSold = 0;
    let props = this.props;
    let filterSkuQty = props.filterSkuQty;
    let filterSkuSales = props.filterSkuSales;
    let fullSkuQty = props.fullSkuQty;
    let fullSkuSales = props.fullSkuSales;

    // Generate the stats per row
    let fstats = stats.map((doc)=>{
      let data = doc.data;
      let name = dot(data,"item.data.sku");
      let qty = parseInt(dot(data,"calcs.sumQuantity")) || 0;
      let sold = salesBySku[name] || 0;
      total += qty;
      totalSold += sold;
      return {
        name: dot(data,"item.data.sku"),
        qty: qty,
        sold: sold,
        skuRate: sold > 0 ? (qty/sold) : null,
        
        subReturnsPercent: filterSkuQty > 0 ? (qty/filterSkuQty) : null,
        subSalesPercent: filterSkuSales > 0 ? (sold/filterSkuSales) : null,
        subRatePercent: filterSkuSales > 0 ? (qty/filterSkuSales) : null,

        fullReturnsPercent: fullSkuQty > 0 ? (qty/fullSkuQty) : null,
        fullSalesPercent: fullSkuSales > 0 ? (sold/fullSkuSales) : null,
        fullRatePercent: fullSkuSales > 0 ? (qty/fullSkuSales) : null
      }
    });

    // Check if sorting is needed
    let srt = this.state[`sortskus`];
    if (srt) { this.sortDataArray(fstats, srt.col, srt.asc); }

    return (<table className="Card StickyHeader" key={"sku-stats"} animate={status}>
      <thead>
        <tr>
          <th></th>
          <th className="Multiheader Period" colSpan="3"></th>
          <th className="Multiheader Subset" colSpan="3" info="Only accounts for the subset of filtered SKUs">Subset</th>
          <th className="Multiheader Fullset" colSpan="3" info="Accounts for all SKUs">Full</th>
        </tr>
        <tr>
          <th className="Name" {...this.genSortProps("skus","name")}>SKU <FontAwesomeIcon icon={["far","sort-up"]}/></th>

          <th className="Count Period" {...this.genSortProps("skus","qty")}>Returns <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Sold Period" {...this.genSortProps("skus","sold")}>Units Sold <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate Period" {...this.genSortProps("skus","skuRate")} info="Returns / Units Sold">
            Return Rate <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>

          <th className="Rate Subset" {...this.genSortProps("skus","subReturnsPercent")} info="Returns / Total Returns">
            Returns % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
          <th className="Rate Subset" {...this.genSortProps("skus","subSalesPercent")} info="Units Sold / Total Units Sold">
            Sold % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
          <th className="Rate Subset" {...this.genSortProps("skus","subRatePercent")} info="Returns / Total Units Sold">
            Rate % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>

          <th className="Rate Fullset" {...this.genSortProps("skus","fullReturnsPercent")}>
            Returns % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
          <th className="Rate Fullset" {...this.genSortProps("skus","fullSalesPercent")}>
            Sold % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
          <th className="Rate Fullset" {...this.genSortProps("skus","fullRatePercent")}>
            Rate % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
        </tr>
      </thead>
      <tbody>
      {fstats.map((data, index)=>{
        return (<tr key={`stats-${index}`} data-blank={!data.name}>
          <td className="Name">{data.name || "–"}</td>
          <td className="Count Period">{commafy(data.qty)}</td>
          <td className="Sold Period">{commafy(data.sold)}</td>
          <td className="Rate Period">{percentify(data.skuRate, "-")}</td>
          <td className="Rate Subset">{percentify(data.subReturnsPercent, "-")}</td>
          <td className="Rate Subset">{percentify(data.subSalesPercent, "-")}</td>
          <td className="Rate Subset">{percentify(data.subRatePercent, "-")}</td>
          <td className="Rate Fullset">{percentify(data.fullReturnsPercent, "-")}</td>
          <td className="Rate Fullset">{percentify(data.fullSalesPercent, "-")}</td>
          <td className="Rate Fullset">{percentify(data.fullRatePercent, "-")}</td>
        </tr>);
      })}
      </tbody>
      <tfoot>
        <tr>
          <td className="Name">Subset Total</td>
          <td className="Count">{commafy(total)}</td>
          <td className="Sold">{commafy(totalSold)}</td>
          <td className="Rate">{totalSold > 0 ? percentify(total/totalSold) : "-"}</td>
        </tr>
      </tfoot>
    </table>);
  }

  renderStats(type, field, title, fieldmap) {
    let props = this.props;
    let stats = dot(props.inventory,[type,"list","docs"]) || [];
    let status = dot(props.inventory,[type,"list","status"]) || "loading";
    let filterSkuQty = props.filterSkuQty;
    let tsalesN = props.fullSkuSales;
    let tskus = props.fullSkuQty;
    let tsales = props.totalSales;
    let total = 0;
    if (!stats) { return null; }

    // Generate the stats per row
    let fstats = stats.map((doc)=>{
      let data = doc.data;
      let value = dot(data,field);
      let name = fieldmap ? fieldmap[value] : dot(value);
      let count = parseInt(dot(data,"calcs.sumQuantity")) || 0;
      total += count;
      return {
        name: fieldmap ? fieldmap[value] : value,
        value: value,
        count: count,
        subReturnsPercent: filterSkuQty > 0 ? (count/filterSkuQty) : null,
        subSalesPercent: tsalesN > 0 ? (count/tsalesN) : null,
        returnsRate: tskus > 0 ? (count/tskus) : null,
        salesRate: tsales > 0 ? (count/tsales) : null
      }
    });

    // Check if sorting is needed
    let srt = this.state[`sort${field}`];
    if (srt) { this.sortDataArray(fstats, srt.col, srt.asc); }

    return (<table className="Card StickyHeader" key={`${type}-stats`} animate={status}>
      <thead>
        <tr>
          <th className="Name"  {...this.genSortProps(field,"name")}>{title} <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Count" {...this.genSortProps(field,"count")}>Returns <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"subReturnsPercent")}>Subset Returns % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"subSalesPercent")}>Subset Sold % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"returnsRate")}>Total Returns % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"salesRate")}>Total Sold % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
        </tr>
      </thead>
      <tbody>
      {fstats.map((data, index)=>{
        return (<tr key={`stats-${index}`}>
          <td className="Name" data-hype={fieldmap ? data.value : ""}>{data.name || "–"}</td>
          <td className="Count">{commafy(data.count)}</td>
          <td className="Rate">{percentify(data.subReturnsPercent, "-")}</td>
          <td className="Rate">{percentify(data.subSalesPercent, "-")}</td>
          <td className="Rate">{percentify(data.returnsRate, "-")}</td>
          <td className="Rate">{percentify(data.salesRate, "-")}</td>
        </tr>);
      })}
      </tbody>
    </table>);
  }

  render() {
    // Get the docs
    return (
      <div className="Page SearchPage ReturnStatsPage">
        <PageHeaderOutline title={this.props.title || "Returns"} top={this._backButton()}>
        </PageHeaderOutline>
        {this.renderMetrics()}
        <Section className="SearchSection" expanded={this.state.expanded}>
          <PaddedArea className="QuerySubsection">
            <QueryLine type="order-returns" typeAlias={TYPE_ALIAS} throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QuerySummaryLine type="order-returns" typeAlias={TYPE_ALIAS} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QueryOptionsPresentation className="Settings" type="order-returns" typeAlias={TYPE_ALIAS} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          </PaddedArea>
        </Section>
        <div className="StatTables NarrowStats">
          {this.renderStats(TYPE_RESOLUTION,'resolution',"Resolution",RETURN_RESOLUTIONS_MAP)}
          {this.renderStats(TYPE_REASON,'reason',"Reasons",RETURN_REASONS_MAP)}
          {this.renderStats(TYPE_DESCRIPTION,'description',"Descriptions")}
          {this.renderSkuStats()}
          {this.renderStats(TYPE_STATUS,'status',"Status",RETURN_STATES_MAP)}
          {this.renderStats(TYPE_RESTOCK,'restocked',"Restocked",BOOL_YN_MAP)}
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {

  let salesBySku = {};
  let totalSales = 0;
  let sales = dot(state.inventory,[TYPE_SALES,"list","docs"]) || [];
  for (var i = 0; i < sales.length; i++) {
    let stats = sales[i];
    let sku = dot(stats,"data.item.data.sku");
    let qty = parseInt(dot(stats,"data.calcs.sumQuantity")) || 0;
    if (sku) { 
      salesBySku[sku] = qty; 
      totalSales += qty;
    }
  }
  
  let skusList = dot(state.inventory,[TYPE_SKUS,"list"]) || {};
  let skus = skusList.docs || [];
  let filterSkuQty = 0;
  let filterSkuSales = 0;
  for (var i = 0; i < skus.length; i++) {
    let sku = dot(skus[i],"data.item.data.sku") || "unknown";
    let qty = parseInt(dot(skus[i],"data.calcs.sumQuantity")) || 0;
    filterSkuQty += qty;
    filterSkuSales += salesBySku[sku] || 0; 
  }

  let fullList = dot(state.inventory,[TYPE_FULL,"list"]) || {};
  let full = fullList.docs || [];
  let fullSkuQty = 0;
  let fullSkuSales = 0;
  for (var i = 0; i < full.length; i++) {
    let sku = dot(full[i],"data.item.data.sku");
    fullSkuSales += sku ? (salesBySku[sku] || 0) : 0;
    fullSkuQty += parseInt(dot(full[i],"data.calcs.sumQuantity")) || 0;
  }

  return {
    skus: skus,
    skusList: skusList,
    filterSkuQty: filterSkuQty,
    filterSkuSales: filterSkuSales,
    query: dot(state.inventory,[TYPE_ALIAS,"list","query"]) || {},
    sales: sales,
    salesBySku: salesBySku,
    totalSales: totalSales,
    fullSkuSales: fullSkuSales,
    fullList: fullList,
    fullSkuQty: fullSkuQty,
    totalRefunds: dot(state.inventory,[TYPE_REFUNDS,"list","docs","0","data","calcs","sumQuantity"]) || 0,
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    queryItems: opts => dispatch(queryItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    loadItem: opts => dispatch(loadItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ReturnStatsPage)
