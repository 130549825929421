// Form Actions
export const START_FORM = "START_FORM";
export const CLEAR_FORM = "CLEAR_FORM";
export const CONFIG_FORM = "CONFIG_FORM";
export const EDIT_FORM = "EDIT_FORM";

// Inventory Actions
export const PURGE_ITEM = "PURGE_ITEM";
export const PURGE_ITEM_LIST = "PURGE_ITEM_LIST";

export const LOAD_ELEMENTS = "LOAD_ELEMENTS";
export const LOAD_ELEMENTS_ERROR = "LOAD_ELEMENTS_ERROR";
export const LOAD_ELEMENTS_SUCCESS = "LOAD_ELEMENTS_SUCCESS";

export const LOAD_POS = "LOAD_POS";
export const LOAD_POS_ERROR = "LOAD_POS_ERROR";
export const LOAD_POS_SUCCESS = "LOAD_POS_SUCCESS";

export const LOAD_PO = "LOAD_PO";
export const LOAD_PO_ERROR = "LOAD_PO_ERROR";
export const LOAD_PO_SUCCESS = "LOAD_PO_SUCCESS";

export const LOAD_ITEM = "LOAD_ITEM";
export const LOAD_ITEM_ERROR = "LOAD_ITEM_ERROR";
export const LOAD_ITEM_SUCCESS = "LOAD_ITEM_SUCCESS";

export const SET_ITEM_QUERY = "SET_ITEM_QUERY";
export const CLEAR_ITEM_QUERY = "CLEAR_ITEM_QUERY";
export const CLEAR_ITEM_FILTER = "CLEAR_ITEM_FILTER";

export const LIST_ITEMS = "LIST_ITEMS";
export const LIST_ITEMS_ERROR = "LIST_ITEMS_ERROR";
export const LIST_ITEMS_SUCCESS = "LIST_ITEMS_SUCCESS";

export const MELD_ITEMS = "MELD_ITEMS";
export const MELD_ITEMS_ERROR = "MELD_ITEMS_ERROR";
export const MELD_ITEMS_SUCCESS = "MELD_ITEMS_SUCCESS";

export const MELD_DOWNLOAD = "MELD_DOWNLOAD";
export const MELD_DOWNLOAD_PROGRESS = "MELD_DOWNLOAD_PROGRESS";
export const MELD_DOWNLOAD_ERROR = "MELD_DOWNLOAD_ERROR";
export const MELD_DOWNLOAD_SUCCESS = "MELD_DOWNLOAD_SUCCESS";

export const QUERY_ITEMS = "QUERY_ITEMS";
export const QUERY_ITEMS_ERROR = "QUERY_ITEMS_ERROR";
export const QUERY_ITEMS_SUCCESS = "QUERY_ITEMS_SUCCESS";

export const START_DRAFT_ITEM = "START_DRAFT_ITEM";
export const PURGE_DRAFT_ITEM = "PURGE_DRAFT_ITEM";

export const SET_ITEM_FIELD = "SET_ITEM_FIELD";
export const INC_ITEM_FIELD = "INC_ITEM_FIELD";
export const CLEAR_ITEM_FIELD = "CLEAR_ITEM_FIELD";
export const REVERT_ITEM_EDITS = "REVERT_ITEM_EDITS";

export const CREATE_ITEM = "CREATE_ITEM";
export const CREATE_ITEM_ERROR = "CREATE_ITEM_ERROR";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";

export const SAVE_ITEM = "SAVE_ITEM";
export const SAVE_ITEM_ERROR = "SAVE_ITEM_ERROR";
export const SAVE_ITEM_SUCCESS = "SAVE_ITEM_SUCCESS";

export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_ERROR = "DELETE_ITEM_ERROR";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

export const SET_ITEM_ACTION = "SET_ITEM_ACTION";

// Menu Actions
export const EXPAND_MENU = "EXPAND_MENU";
export const COLLAPSE_MENU = "COLLAPSE_MENU";

// Popup Actions
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

// REST Actions
export const REQUEST_GET = "REQUEST_GET";
export const REQUEST_GET_ERROR = "REQUEST_GET_ERROR";
export const REQUEST_GET_SUCCESS = "REQUEST_GET_SUCCESS";

// Session Action
export const SET_SESSION_LOGIN = "SET_SESSION_LOGIN";
export const SET_SESSION_BOOK = "SET_SESSION_BOOK";
export const LOGOUT_SESSION = "LOGOUT_SESSION";

// AWS Actions
export const POST_BUCKET_FILE = "POST_BUCKET_FILE";
export const POST_BUCKET_FILE_ERROR = "POST_BUCKET_FILE_ERROR";
export const POST_BUCKET_FILE_SUCCESS = "POST_BUCKET_FILE_SUCCESS";

// App Actions
export const SET_APP_FIELD = "SET_APP_FIELD";
export const INC_APP_FIELD = "INC_APP_FIELD";
export const CLEAR_APP_FIELD = "CLEAR_APP_FIELD";
export const TOGGLE_APP_FIELD = "TOGGLE_APP_FIELD";

export const PUSH_APP_NOTIFICATION = "PUSH_APP_NOTIFICATION";
export const CLEAR_APP_NOTIFICATION = "CLEAR_APP_NOTIFICATION";
export const KEEP_APP_NOTIFICATION = "KEEP_APP_NOTIFICATION";
export const POP_APP_NOTIFICATION = "POP_APP_NOTIFICATION";
