import React from 'react';
import { connect } from 'react-redux'
import './TableSearchSection.scss';

import ActionVal from '../containers/ActionVal';
import BareInput from '../elements/BareInput';
import DateVal from '../containers/DateVal';
import CheckBoxVal from '../containers/CheckBoxVal';
import Draft from '../../lib/draft.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelLayout from '../layouts/ModelLayout';
import ModelSearchSection from '../sections/ModelSearchSection';
import RectButton from '../elements/RectButton';
import Separator from '../elements/Separator';
import ImageLayout from '../layouts/ImageLayout';

import { purgeItem, pushItem, qItem, deleteItem, startDraftItem, setItemField } from '../../actions/InventoryActions';
import { dot } from '../../lib/obj';
import { stateForList } from '../../lib/inventory-util';
import { singularType } from '../../constants/inventory/Types'
import { datify } from '../../lib/formats';
import { mDownload } from '../../lib/file';
import { _ } from '../../lib/underscore';

const DOUBLE_CLICK_MS_LIMIT = 200;
/*
LAYOUT ARRAY EXAMPLE
layout: [
  {
    title: "Name",
    className: ""
    editable: true | false,
    type: "string" | "text" | "date" | "number" | "image" | "dropdown",
    field: "name",
    options: ["val1",],
    render: func()
  }
  ...
]

actions: [
  {
    title: "Create PO"
    icon: "po",
  }
]

// Callbacks

onSelections(items, indexes)
onDeselections(items, indexes)

// Called when an item is added
// cb is a callback that should get called by passing in the data for the new item
onAddItem(cb)

beforeEdit(draft, field, index)
afterEdit(draft, field, index)
beforeDelete(item, index)
afterDelete(item, index)
onAction(action, items, indexes)
*/

const STATUS_WORKING = {
  creating: true,
  saving: true,
  deleting: true
}

class TableSearchSection extends React.Component  {

  constructor(){
    super()
    this.state = {
      texts: {},
      editing: {}
    };
    this._clicks = {};
  }

  renderHeader() {
    let layout = this.props.layout || [];

    let controls;
    if (this.props.controllable) {
      controls = <th className="Controls"><FontAwesomeIcon className="ControlIcon" icon={["fal","plus"]} onClick={(e)=>{this.onAddItem(e)}}/></th>
    }

    let check;
    if (this.props.checkable) {
      check = <th className="Check">{this.genSelectsDiv()}</th>
    }

    return <tr>
      {controls}
      {check}
      {layout.map((conf,i)=>{
        let className = conf.className || (conf.title ? conf.title.replace(" ","_") : "");
        return <th key={`th${i}`} className={`Th${i} ${className}`}>{conf.title}</th>
      })}
    </tr>;
  }

  renderItem(item, index) {
    let selectKey = this.genSelectKey(item,index);
    let selected = this.state[selectKey];
    let data = item.data || {};
    let layout = this.props.layout || [];
    let editing = data.id && this.state.editing[data.id];

    let controls;
    if (this.props.controllable) {
      controls = <td className="Controls">
        {this.genControlsDiv(item, index)}
      </td>
    }

    let check;
    if (this.props.checkable) {
      check = <td className="Check" onClick={(e)=>{this.setState({[selectKey]:!selected})}}>
        <CheckBoxVal selected={selected}/>
      </td>
    }

    let key = `item${index}-id${data.id || "0"}`; //`item${index}-id${data.id || "0"}-v${data.version || "0"}`;
    return <tr key={key}
      data-working={STATUS_WORKING[item.status]}
      data-creating={data.id == null}
      onClick={(e)=>{this.onRowClick(e, item, index)}}
      >
      {controls}
      {check}
      {layout.map((conf,i)=>{
        let className = conf.className || (conf.title ? conf.title.replace(" ","_") : "");
        return <td key={`td${i}`} className={`Td${i} ${className}`} data-type={conf.type} data-hype={conf.hype}>
          {data.id && (conf.readonly || !editing) ? this.renderReadonlyColConf(item, conf, index) : this.renderColConf(item, conf, index)}
        </td>
      })}
    </tr>;
  }

  renderColConf(item, conf, index) {
    let val = dot(item.data, conf.field);
    if (val === undefined) { val = conf.default; }
    let el;
    switch (conf.type) {
      case "action":
        el = <ActionVal onClick={(e,h)=>{conf.onClick(e,h,item,index)}} data-hype={conf.hype}>{conf.mod ? conf.mod(val) : val}</ActionVal>
        break;
      case "string":
        el = <BareInput defaultValue={val} onBlurOrEnter={(e)=>{this.saveField(conf, e.target.value, val, index)}}/>
        break;
      case "date":
        let dateVal = Date.utcTransDateInput(val);
        el = <BareInput type="date" data-hype={dateVal ? "" : "blank"} defaultValue={ dateVal } onChange={(e)=>{this.saveDateField(conf, e.target.value, dateVal, index)}}/>
        break;
      case "number":
        el = <BareInput defaultValue={val} type="number" onBlurOrEnter={(e)=>{this.saveField(conf, e.target.value, val, index)}}/>
        break;
      case "money":
        el = <BareInput step="0.01" defaultValue={val} type="number" onBlurOrEnter={(e)=>{this.saveField(conf, e.target.value, val, index)}}/>
        break;
      case "image":
        el = <ImageLayout className="ImageMini" file={val} onUpload={(file)=>this.saveImage(conf, file, val, index)}/>
        break;
      case "dropdown":
        let options = conf.options || [];
        el = <select defaultValue={val} onChange={(e)=>{this.saveField(conf, e.target.value, val, index)}} data-hype={val || 'undefined'}>
          {options.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
        </select>
        break;
      case "text":
        el = this.genTextDiv(conf, item, val, index);
        break;
      case "custom":
        el = conf.render ? conf.render(item, index) : "-";
        break;
      case "plain":
      default:
        el = <div className="PlainVal">{val}</div>
    }
    return el;
  }

  renderReadonlyColConf(item, conf, index) {
    let val = dot(item.data, conf.field);
    let el;
    switch (conf.type) {
      case "action":
        el = <ActionVal onClick={(e,h)=>{conf.onClick(e,h,item)}} data-hype={conf.hype}>{conf.mod ? conf.mod(val) : val}</ActionVal>
        break;
      case "date":
        el = <div className="PlainVal">{datify(val)}</div>;
        break;
      case "image":
        el = <ImageLayout className="ImageMini" file={val}/>
        break;
      case "dropdown":
        let options = conf.options || [];
        el = <select defaultValue={val} onChange={(e)=>{this.saveField(conf, e.target.value, val, index)}} data-hype={val || 'undefined'} disabled>
          {options.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
        </select>
        break;
      case "text":
        el = this.genTextDiv(conf, item, val, index);
        break;
      case "custom":
        el = conf.render ? conf.render(item, index) : "-";
        break;
      case "plain":
      default:
        if (conf.onClick) {
          el = <ActionVal onClick={(e,h)=>{conf.onClick(e,h,item)}} data-hype={conf.hype}>{conf.mod ? conf.mod(val) : val}</ActionVal>
        }
        else {
          el = <div className="PlainVal">{val}</div>
        }
    }
    return el;
  }

  renderBareColConf(item, conf, index) {
    let val = dot(item.data, conf.field);
    switch (conf.type) {
      case "action":
        val = conf.mod ? conf.mod(val) : val;
        break;
      case "date":
        val = datify(val);
        break;
      case "custom":
        val = (conf.render ? conf.render(item, index) : "-");
        break;
    }
    return val;
  }

  genSelectsDiv() {
    let items = this.props.items;
    let selections = items.filter((item,i)=>this.state[this.genSelectKey(item,i)]);
    let actions = this.props.actions || [];
    if (selections.length > 0) {
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="ControlIcon" icon={["far","square"]} />
        </summary>
        <div className="DetailsList">
          <div className="IconAction" onClick={()=>{this.setItemsSelect(selections,false)}}>
            <FontAwesomeIcon icon={["far","square-minus"]} /> Deselect All
          </div>
          <Separator collapsed={true}/>
          <div className="IconAction" onClick={()=>{this.onDownload(selections)}}>
            <FontAwesomeIcon icon={["fa","download"]} /> Download
          </div>
          {actions.map((conf,i)=>{
            return <div className="IconAction" onClick={()=>{this.onAction(conf, selections)}}>
              <FontAwesomeIcon icon={ conf.icon || ["far","square-minus"]} /> { conf.title }
            </div>
          })}
        </div>
      </details>
    }
    else {
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="ControlIcon" icon={["far","square"]} />
        </summary>
        <div className="DetailsList">
          <div className="IconAction" onClick={()=>{this.setItemsSelect(items,true)}}>
            <FontAwesomeIcon icon={["far","square-plus"]} /> Select All
          </div>
        </div>
      </details>
    }
  }

  onRowClick(event, item, index) {
    let lce = this._clicks[index];
    let epoc = Date.now();
    this._clicks[index] = Date.now();
    if(!lce || (epoc - lce) > DOUBLE_CLICK_MS_LIMIT) {
      return;
    }
    if (document.activeElement === event.target) {
      return;
    }
    if (!dot(item,"data.id")) {
      this._pushItem(index);
    }
    else {
      let editing = this.state.editing;
      editing[item.data.id] = true;
      this.setState({editing:editing})
    }
  }

  onDownload(items) {
    let date = new Date();
    // Lets group items
    let csvContent = items.map((item, index)=>{
      return '"' + this.bareItemVals(item, index).join('","') + '"';
    });
    csvContent.unshift('"' + this.bareItemHeaders().join('","') + '"');
    let fname = `${date.toDateString()} ${date.toTimeString().split(" ")[0]}.csv`;
    mDownload(csvContent.join("\n"),fname,'text/csv;encoding:utf-8');
  }

  bareItemVals(item, index) {
    let layout = this.props.layout || [];
    return layout.map((conf)=>{
      return this.renderBareColConf(item, conf, index);
    });
  }

  bareItemHeaders() {
    let layout = this.props.layout || [];
    return layout.map((conf)=>{
      return conf.title || "";
    });
  }

  genTextDiv(conf, item, val, index) {
    if (item) {
      let data = item.data;
      let texts = this.state.texts;
      let txt = dot(this.state,["texts", conf.field, index]);
      let edited = txt != null && txt != val;
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="Icon" icon={["far","memo-pad"]} data-filled={val ? true : false} />
        </summary>
        <div className="DetailsList">
          <textarea name="notes" style={{width:"100%",minHeight:"10em"}} defaultValue={val} onChange={(e)=>this.onTextChange(e, conf, index)}></textarea>
          <RectButton theme="blue" lock={!edited} working={STATUS_WORKING[item.status]} onClick={(e)=>{this.saveField(conf, txt, val, index)}}>Save</RectButton>
        </div>
      </details>
    }
  }

  onTextChange(e, conf, index) {
    let texts = this.state.texts;
    dot(texts,[conf.field, index], e.target.value);
    this.setState({texts:texts});
  }

  setItemsSelect(items, select=false) {
    let nuState = {}
    items.forEach((item, i) => {
      nuState[this.genSelectKey(item,i)] = select
    });
    this.setState(nuState);
  }

  genSelectKey(item,index) {
    let id = dot(item,"data.id");
    return id ? `_s${id}i` : `_s${index}`;
  }

  genControlsDiv(item, index) {
    let data = item.data || {};
    if (data.id > 0) {
      let controls = this.props.controls || [];
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="ControlIcon" icon={["fal","ellipsis-v"]}/>
        </summary>
        <div className="DetailsList">
          <div className="Header">ID:{data.id} <aside><DateVal>{data.createdAt}</DateVal></aside></div>
          <Separator collapsed={true}/>
          {controls.map((conf,i)=>{
            return <div className="IconAction" onClick={()=>{this.onControl(conf, item, index)}}>
              <FontAwesomeIcon icon={ conf.icon || ["far","square-minus"]} /> { conf.title }
            </div>
          })}
          <RectButton working={item.status == "deleting"} theme="red" onClick={(e,history)=>{this.onDeleteItem(e, item.data.id, index)}}>Delete</RectButton>
        </div>
      </details>
    } else {
      return <FontAwesomeIcon className="ControlIcon" icon={["far","times"]} onClick={(e)=>{this.onDeleteItem(e, item.data.id, index)}}/>
    }
  }

  onAddItem(e) {
    let modelProps = this.modelProps();
    let cb=(data={})=>{
      if (_.isArray(data)) {
        data.forEach((d,i) => {
          this.props.startDraftItem({
            ...modelProps,
            index: -1,
            data: d
          });
        });
      }
      else {
        this.props.startDraftItem({
          ...modelProps,
          index: -1,
          data: data
        });
      }
    }
    if (this.props.onAddItem) {
      this.props.onAddItem(cb);
    }
    else {
      cb();
    }
  }

  saveImage(conf, value, orig, index) {
    let item = dot(this.props.items, index);
    let field = conf.saveField || conf.field;
    this.saveField(conf, dot(value,"data.id"), dot(item,field), index);
  }

  saveField(conf, value, orig, index) {
    let modelProps = this.modelProps();
    let field = conf.saveField || conf.field;

    // Check for changes
    if (value == orig || (value == "" && orig == null)) { return; }

    // Get the latest item
    let item = dot(this.props.items, index);

    // Help prevent double entries
    if (dot(item,"status") == "creating") { return; }

    // Update values right away
    this.props.setItemField({...modelProps, index: index, field: field, value: value });

    // Get draft changes in case of multiple edits
    let draft = Draft.deep(dot(item,"data"));

    // Clear or set the new value
    if (conf.type == "date" && (!value || value == "")) {
      draft.clear(field)
    } else {
      draft.set(field, value);
    }

    // Push the item
    let singular = singularType(modelProps);
    let links = dot(this.props,"query.links");
    this.props.qItem({
      ...modelProps,
      index: index,
      id: draft.val("id"),
      docs: { [singular]: draft.updates },
      query: {
        links: links ? links.join(",") : undefined,
        promptError:true
      },
      after: (res) => {
        let rd = dot(res,[singular,"data"]);
        if (dot(rd,"version") == 1) {
          let editing = this.state.editing;
          editing[dot(rd.id)] = true;
          this.setState({editing:editing})
        }
        if (this.props.onItemPush) {
          this.props.onItemPush(dot(res,singular));
        }
      }
    });
  }

  _pushItem(index) {
    let modelProps = this.modelProps();

    // Get the latest item
    let item = dot(this.props.items, index);

    // Help prevent double entries
    if (dot(item,"status") == "creating") { return; }

    // Get the draft
    let draft = Draft.deep(dot(item,"data"));

    // Push the item
    let singular = singularType(modelProps);
    let links = dot(this.props,"query.links");
    this.props.qItem({
      ...modelProps,
      index: index,
      id: draft.val("id"),
      docs: { [singular]: draft.updates },
      action: "create", // Force create
      query: {
        links: links ? links.join(",") : undefined,
        promptError:true
      },
      after: (res) => {
        let rd = dot(res,[singular,"data"]);
        if (dot(rd,"version") == 1) {
          let editing = this.state.editing;
          editing[dot(rd.id)] = true;
          this.setState({editing:editing})
        }
        if (this.props.onItemPush) {
          this.props.onItemPush(dot(res,singular));
        }
      }
    });
  }

  saveDateField(conf, value, orig, index) {
    // Convert date to approprite format
    if (value && value.length > 0) {
      let date = Date.parseYMDStr(value);
      value = date.srvstr({utc:true})
    }
    this.saveField(conf, value, orig, index);
  }

  onDeleteItem(e, id, index) {
    let modelProps = this.modelProps();
    if (id > 0) {
      this.props.deleteItem({...modelProps, id: id,index: index})
      .then(()=>{
        this.props.purgeItem({...modelProps, id: id, index: index});
      });
    }
    else {
      this.props.purgeItem({...modelProps, index: index});
    }
    this.setState({sci: null, notes: {}});
  }

  modelProps() {
    let props = this.props;
    return {
      type: props.type,
      typeAlias: props.typeAlias,
      id: props.id,
      idAlias: props.idAlias,
      childType: props.childType,
      childTypeAlias: props.childTypeAlias,
      childId: props.childId
    }
  }

  render() {
    let props = this.props;
    let modelProps = this.modelProps();
    let className = this.props.className || "";
    return (
      <ModelSearchSection
        className={`TableSearchSection ${className}`}
        {...modelProps}
        query={props.query}
        conditions={props.conditions}
        renderHeader={()=>this.renderHeader()}
        renderItem={(item,i)=>this.renderItem(item,i)}
        reload={this.props.reload}
        label={props.label}
        disableSearch={props.disableSearch}
        onQuerySuccess={props.onQuerySuccess}
      />
    );
  }

}

const mapState = (state, props) => {
  let list = stateForList(state.inventory, props) || {};
  return {
    list: list,
    status: props.status || list.status,
    items: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return {
    deleteItem: opts => dispatch(deleteItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    qItem: opts => dispatch(qItem(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    setItemField: opts => dispatch(setItemField(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(TableSearchSection)
