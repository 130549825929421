import React from 'react';
import { mapk2k } from '../../lib/obj'
import * as FILTERS from '../misc/QueryFilters';


export const RESOLUTION_PRIORITIES = [
  { value:"normal",  name: "Normal",  desc: "Normal priority"},
  { value:"urgent",  name: "Urgent",  desc: "Urgent priority"}
];

export const RESOLUTION_ACTIONS = [
  { value:"unknown",        name: "Unknown",        desc: "Unknown action"},
  { value:"complimentary",  name: "Complimentary",  desc: "Complimentary item"},
  { value:"customized",     name: "Customized",     desc: "Customized order"},
  { value:"exchange",       name: "Exchange",       desc: "Product exchange"},
  { value:"replace",        name: "Replace",        desc: "Product replacement"},
  { value:"reship",         name: "Reship",         desc: "Reship a product"},
];

export const RESOLUTION_STATES = [
  { value:"initiated",    name: "Initiated",    desc: "The return has been initiated"},
  { value:"incomplete",   name: "Incomplete",   desc: "More info is required"},
  { value:"complete",     name: "Complete",     desc: "Order is complete"},
  { value:"invoiced",     name: "Invoiced",     desc: "Invoice has been sent to the customer"}
];

export const RESOLUTION_REASONS = [
  { value:"unknown",            name: "Unknown",          desc: "Unknown reason"},
  { value:"assembly",           name: "Assembly",         desc: "Error durring packaging or assembly"},
  { value:"complimentary",      name: "Complimentary",    desc: "Complimentary product"},
  { value:"customized",         name: "Customized",       desc: "Customized order"},
  { value:"damaged",            name: "Damaged",          desc: "Product was broken or damaged"},
  { value:"defective",          name: "Defective",        desc: "Product was defective"},
  { value:"design",             name: "Design",           desc: "Product design"},
  { value:"exchange",           name: "Exchange",         desc: "Product is being exchanged for another"},
  { value:"functionality",      name: "Functionality",    desc: "Did not function as expected"},
  { value:"lost",               name: "Lost Item",        desc: "An item was lost"},
  { value:"mistake",            name: "Customer Mistake", desc: "Customer mistake"},
  //{ value:"missing",            name: "Missing Item",     desc: "An item was not shipped"},
  //{ value:"quality",            name: "Quality",          desc: "Bad quality"},
  { value:"wrong",              name: "Shipping Mistake", desc: "Wrong product shipped to customer"},
  { value:"support",            name: "Support Mistake",  desc: "Support made a mistake"},
  { value:"undeliverable",      name: "Undeliverable",    desc: "Return to sender, rejected by customs, invalid address, etc"},
  { value:"reship",             name: "Reship",           desc: "Reship an item"}
];

export const RESOLUTION_DESCRIPTIONS = [
  { value:"N/A",                      reason: "unknown"},

  { value:"Missing parts or components",  reason: "assembly", action:"reship"},
  { value:"Wrong parts or components",    reason: "assembly", action:"reship"},
  { value:"Buttons missing",              reason: "assembly", action:"reship"},

  { value:"Complimentary",                reason: "complimentary",  action:"complimentary"},
  { value:"For use with Tesla Charger",   reason: "complimentary",  action:"complimentary"},
  { value:"For use with Single Charger",  reason: "complimentary",  action:"complimentary"},
  { value:"For use with Wallet",          reason: "complimentary",  action:"complimentary"},
  { value:"For use with Case",            reason: "complimentary",  action:"complimentary"},
  { value:"For use with Phone ",          reason: "complimentary",  action:"complimentary"},

  { value:"Customized", reason: "customized",  action:"customized"},

  { value:"Damaged",                  reason: "damaged",  action:"reship"},
  { value:"Damaged During Shipping",  reason: "damaged",  action:"reship"},

  //{ value:"Cosmetic Damage",          reason: "defective",      action:"replace"},
  { value:"Cosmetic defect",          reason: "defective",      action:"replace"},
  { value:"Defective",                reason: "defective",      action:"replace"},
  { value:"Fit Issue",                reason: "defective",      action:"replace"},
  { value:"Adhesive Issue",           reason: "defective",      action:"replace"},
  { value:"Lexan label peeling",      reason: "defective",      action:"replace"},
  { value:"USB-C damaged ",           reason: "defective",      action:"replace"},
  { value:"Fingerloop damaged ",      reason: "defective",      action:"replace"},
  { value:"Damaged (screw and/or washer)", reason: "defective", action:"replace"},
  { value:"Adapter not working ",     reason: "defective",      action:"replace"},
  { value:"Flashing light ",          reason: "defective",      action:"replace"},
  { value:"DOA",                      reason: "defective",      action:"replace"},

  { value:"Case loose on the side",   reason: "defective",      action:"replace"},
  { value:"Sensitive buttons",        reason: "design",         action:"replace"},
  { value:"SP bubbling",              reason: "design",         action:"replace"},
  { value:"SP cracked",               reason: "design",         action:"replace"},
  { value:"LP cracked",               reason: "design",         action:"replace"},
  { value:"Kickstand broken",         reason: "design",         action:"replace"},
  { value:"Staining",                 reason: "design",         action:"replace"},
  { value:"Weak magnets",             reason: "design",         action:"replace"},
  { value:"Broken buttons",           reason: "design",         action:"replace"},
  { value:"Fallen buttons",           reason: "design",         action:"replace"},

  { value:"SP installation issues",   reason: "defective",      action:"replace"},
  { value:"SP bubbling",              reason: "defective",      action:"replace"},

  { value:"Discoloration",                reason: "design",         action:"replace"},
  { value:"Peeling",                      reason: "design",         action:"replace"},
  { value:"Parts break during install",   reason: "design",         action:"replace"},
  { value:"Difficult to install",         reason: "design",         action:"replace"},
  { value:"Back of the case ripped",      reason: "design",         action:"replace"},
  { value:"Elite accessory part broken",  reason: "design",         action:"replace"},
  { value:"Pinky pillow ripped",          reason: "design",         action:"replace"},
  { value:"Case ripped",                  reason: "design",         action:"replace"},

  { value:"Exchange",                 reason: "exchange",       action:"exchange"},
  { value:"Ordered wrong product",    reason: "exchange",       action:"exchange"},
  { value:"Unhappy with product",     reason: "exchange",       action:"exchange"},
  { value:"Received wrong item",      reason: "exchange",       action:"exchange"},
  { value:"Did not fit needs",        reason: "exchange",       action:"exchange"},

  { value:"Accidental return",        reason: "mistake",        action:"exchange"},

  { value:"Vibration",                reason: "functionality",  action:"replace"},
  { value:"Not Charging ",            reason: "functionality",  action:"replace"},
  { value:"Slow Charging ",           reason: "functionality",  action:"replace"},
  { value:"Parts fail or fall off",   reason: "functionality",  action:"replace"},
  { value:"NFC payments not working", reason: "functionality",  action:"replace"},
  { value:"Telescoping magnet issue", reason: "functionality",  action:"replace"},

  { value:"Received Empty Package", reason: "lost", action:"reship"},
  { value:"Lost in Transit USPS",   reason: "lost", action:"reship"},
  { value:"Lost in Transit FedEx",  reason: "lost", action:"reship"},

  { value:"Wrong order created",             reason: "support", action:"reship"},
  { value:"Item missing when order created", reason: "support", action:"reship"},

  { value:"DNR-USPS",   reason: "undeliverable",  action:"reship"},
  { value:"DNR-FedEx",  reason: "undeliverable",  action:"reship"},
  { value:"RS-USA",     reason: "undeliverable",  action:"reship"},
  { value:"RS-Intl",    reason: "undeliverable",  action:"reship"},

  { value:"Wrong Item Sent",        reason: "wrong",    action:"reship"},
  { value:"Missing an item",        reason: "wrong",    action:"reship"},
  { value:"Wrong shipping method",  reason: "wrong",    action:"reship"},

  { value:"Reship",                 reason: "reship",   action:"reship"},
];

export const RESOLUTION_CARRIERS = [
  { value:"unknown",    name: "Unknown",    desc: "Unknown carrier"},
  { value:"fedex",      name: "FedEx",      desc: "FedEx Service"},
  { value:"usps",       name: "USPS",       desc: "United States Postal Service"},
  { value:"ups",        name: "UPS",        desc: "United Parcel Service"},
  { value:"dhl",        name: "DHL",        desc: "Dalsey, Hillblom and Lynn Service"},
  { value:"other",      name: "Other",      desc: "Other or unknown"}
];

export const RESOLUTION_ORIGINS = [
  { value:"shopify",    name: "Shopify",    desc: "Shopify"},
  { value:"amazon",     name: "Amazon",     desc: "Amzon"},
  { value:"other",      name: "Other",      desc: "Other or unknown"}
];

export const RESOLUTION_SEARCH = {
  type: "order-resolutions",
  links: ["order","item","newOrder","newItem","completedBy"],
  limit: 50,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["mainOrder.email","mainOrder.name","refName","item.sku"],
    keymap: {
      id: {field:"id", op:"eq"},
      ref: "refName",
      name: "refName",
      order:"mainOrder.name",
      sku: "item.sku",
      newsku: "newProduct.sku",
      email: "mainOrder.email",
      product: "item.name",
      reason: "reason",
      description: "description",
      agent: "completedBy.email",
      action: "action"
    }
  },
  filters: [
    {
      label: "Status",
      type: "check",
      field: "status",
      options: RESOLUTION_STATES
    },
    {
      label: "Action",
      type: "check",
      field: "action",
      options: RESOLUTION_ACTIONS
    },
    {
      label: "Priority",
      type: "check",
      field: "priority",
      options: RESOLUTION_PRIORITIES
    },
    {
      label: "Reasons",
      type: "check",
      field: "reason",
      options: RESOLUTION_REASONS
    },
    {
      label: "Origin",
      type: "check",
      field: "origin",
      options: RESOLUTION_ORIGINS
    },
    {
      label: "Created",
      type: "daterange",
      radio: true,
      field: "createdAt",
      options: FILTERS.DATERANGE_OPTIONS
    },
    {
      label: "Completed",
      type: "daterange",
      radio: true,
      field: "completedAt",
      options: FILTERS.DATERANGE_OPTIONS
    },
    // {
    //   label: "Group",
    //   type: "check",
    //   options: FILTERS.PRODUCT_GROUP_OPTIONS
    // }
  ],
  sort: [
    { field: "createdAt",       name: "Created (Oldest to Newest)",    direction:"asc"},
    { field: "createdAt",       name: "Created (Newest to Oldest)",    direction:"desc",  selected: true},
    { field: "updatedAt",       name: "Updated (Oldest to Newest)",    direction:"asc"},
    { field: "updatedAt",       name: "Updated (Newest to Oldest)",    direction:"desc"},
    { field: "completedAt",     name: "Completed (Oldest to Newest)",  direction:"asc"},
    { field: "completedAt",     name: "Completed (Newest to Oldest)",  direction:"desc"},
  ]
}

// Mainly used for stats
export const RESOLUTION_BASE_SEARCH = {
  type: "order-resolutions",
  links: ["item","newItem"],
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["item.name","item.sku", "description","reason",],
    keymap: { // TODO - Fernando Mora: Implement this functionality
      sku: "item.sku",
      newsku: "newProduct.sku",
      email: "mainOrder.email",
      product: "item.name",
      reason: "reason",
      fulfillment: "fulfillmentStatus",
      resolution: "resolution",
      description: "description",
      action: "action"
    }
  },
  filters: [
    {
      label: "Reasons",
      type: "check",
      field: "reason",
      options: RESOLUTION_REASONS
    },
    {
      label: "Status",
      type: "check",
      field: "status",
      options: RESOLUTION_STATES
    },
    {
      label: "Created",
      type: "daterange",
      radio: true,
      field: "createdAt",
      options: FILTERS.DATERANGE_OPTIONS
    },
    {
      label: "Completed",
      type: "daterange",
      radio: true,
      field: "completedAt",
      options: FILTERS.DATERANGE_OPTIONS
    }
  ],
  where:[]
}

export const RESOLUTION_DESCRIPTION_SEARCH = {
  type: "order-resolutions",
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["description"]
  },
  fields: "description",
  calcs: "count.id",
  group: "description",
  limit: 25
}


export const RESOLUTION_PRIORITIES_MAP = mapk2k(RESOLUTION_PRIORITIES,"value", "name");
export const RESOLUTION_ACTIONS_MAP = mapk2k(RESOLUTION_ACTIONS,"value","name");
export const RESOLUTION_STATES_MAP = mapk2k(RESOLUTION_STATES,"value","name");
export const RESOLUTION_REASONS_MAP = mapk2k(RESOLUTION_REASONS,"value","name");
export const RESOLUTION_CARRIERS_MAP = mapk2k(RESOLUTION_CARRIERS,"value","name");
export const RESOLUTION_DESCRIPTIONS_MAP = mapk2k(RESOLUTION_DESCRIPTIONS,"value");
