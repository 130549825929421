import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import PaddedArea from '../areas/PaddedArea';
import Section from '../elements/Section';
import Input from '../elements/Input';

import QueryLine from '../layouts/QueryLine';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openPopup, closePopup } from '../../actions/PopupActions';
import { startDraftItem, purgeDraftItem, setItemField } from '../../actions/InventoryActions';
import { loadItem, meldItems, queryItems, setItemQuery, pushItem } from '../../actions/InventoryActions';
import { dot, reg, deepCopy, mapk2k, mappify } from '../../lib/obj';
import { configToNestedQuery } from '../../lib/inventory-util';

import { _ } from '../../lib/underscore';
import { percentify, commafy } from '../../lib/formats';
import { queryToWhere } from '../../lib/inventory-util';

import './Page.css';
import './SearchPage.scss';
import './ResolutionStatsPage.scss';
import  '../../lib/date-util';

import { RESOLUTION_BASE_SEARCH, RESOLUTION_STATES_MAP, RESOLUTION_REASONS_MAP, RESOLUTION_ACTIONS_MAP } from '../../constants/inventory/OrderResolution'


const CUSTOM_RESOLUTION_SEARCH = deepCopy(RESOLUTION_BASE_SEARCH);

const TYPE_NAME = "order-resolutions";
const TYPE_ALIAS = "order-resolution-stats";
const TYPE_FULL = "order-resolution-full";
const TYPE_REASON = "order-resolution-reasons";
const TYPE_DESCRIPTION = "order-resolution-descriptions";
const TYPE_STATUS = "order-resolution-statuses";
const TYPE_SKUS = "order-resolution-skus";
const TYPE_ACTIONS = "order-resolution-actions";
const TYPE_SALES = "product-sales";

export const ORDER_ITEM_SEARCH = {
  type: "order-items",
  links: ["order","item"]
}

export const RESOLUTION_PRODUCT_FIELDS = [
  { value: "itemId",    qtyField: "quantity",    sumField: "calcs.sumQuantity",    skuField: "item.data.sku",    name: "Original Product",   desc: "Show stats based on original product quantities"},
  { value: "newItemId", qtyField: "newQuantity", sumField: "calcs.sumNewQuantity", skuField: "newItem.data.sku", name: "New Product",        desc: "Show stagas based on new product quantities"},
];

class ResolutionStatsPage extends Page {

  constructor() {
    super();
    this.state.expanded = false;
    this.state.productFieldConfig = RESOLUTION_PRODUCT_FIELDS[0];
  }

  componentWillMount() {
    let createdFilter = CUSTOM_RESOLUTION_SEARCH.filters.find(f=>f.field=="createdAt");
    createdFilter.options[2].selected = true; // Last 7 days

    let props = this.props;
    let baseConfig = deepCopy(CUSTOM_RESOLUTION_SEARCH);
    props.setItemQuery({
      type: "order-resolutions",
      typeAlias: TYPE_ALIAS,
      query: baseConfig
    });
    // Load stats
    this.loadStatsWithBaseConfig(CUSTOM_RESOLUTION_SEARCH);
  }


  onProductFieldChange(value) {
    let config = RESOLUTION_PRODUCT_FIELDS.find(r=>r.value==value)
    // Prepare condition and update state
    this.setState({
      productFieldConfig: config
    },()=>{
      // Load stats
      this.loadStatsWithBaseConfig(this.props.query);
    });
  }

  // Get the query config
  onConfigChange(config, throttle) {
    // Load stats
    this.loadStatsWithBaseConfig(config, throttle);
  }

  loadStatsWithBaseConfig(config, throttle) {

    let state = this.state;
    let conditions = [{deleted:{neq:1}}];
    let pfc = state.productFieldConfig;

    const epoc = Date.now();

    // Build the stats queries
    let fullConfig = deepCopy(CUSTOM_RESOLUTION_SEARCH);
    let fullQ = configToNestedQuery(fullConfig, deepCopy(conditions));
    fullQ.fields = ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","newItemId","newItem.id","newItem.bookId","newItem.sku"];
    fullQ.calcs  = [`sum.${pfc.qtyField}`];
    fullQ.group  = [pfc.value];    
    this.props.meldItems({
      query: fullQ,
      type: "order-resolutions",
      typeAlias: TYPE_FULL,
      epoc: epoc
    });

    // Create the base period query 
    let periodQuery = configToNestedQuery(deepCopy(config), deepCopy(conditions));
    let createdCondition = periodQuery.where.find(c=>c["createdAt"]); 
    let completedConditon = periodQuery.where.find(c=>c["completedAt"]); 
    let rangeCondition = createdCondition ? createdCondition["createdAt"] : null;
    if (!rangeCondition) {
      rangeCondition = completedConditon ? completedConditon["completedAt"] : null;
    }

    // Handle to all the meld calls 
    const calls = []; 

    // Build the stats queries
    let skuQ = deepCopy(periodQuery);
    skuQ.fields = ["id","bookId","createdAt","itemId","item.id","item.bookId","item.sku","newItemId","newItem.id","newItem.bookId","newItem.sku"];
    skuQ.calcs  = [`sum.${pfc.qtyField}`];
    skuQ.group  = [pfc.value];
    calls.push(this.props.meldItems({
      query: skuQ,
      type: "order-resolutions",
      typeAlias: TYPE_SKUS,
      epoc: epoc
    }));

    let actionsQ = deepCopy(periodQuery);
    actionsQ.fields = ["id","bookId","createdAt","action"];
    actionsQ.calcs  = [`sum.${pfc.qtyField}`];
    actionsQ.group  = ["action"];
    calls.push(this.props.meldItems({
      query: actionsQ,
      type: "order-resolutions",
      typeAlias: TYPE_ACTIONS,
      epoc: epoc
    }));

    let reasonsQ = deepCopy(periodQuery);
    reasonsQ.fields = ["id","bookId","createdAt","reason"];
    reasonsQ.calcs  = [`sum.${pfc.qtyField}`];
    reasonsQ.group  = ["reason"];
    calls.push(this.props.meldItems({
      query: reasonsQ,
      type: "order-resolutions",
      typeAlias: TYPE_REASON,
      epoc: epoc
    }));

    let descriptionQ = deepCopy(periodQuery);
    descriptionQ.fields = ["id","bookId","createdAt","description"];
    descriptionQ.calcs  = [`sum.${pfc.qtyField}`];
    descriptionQ.group  = ["description"];
    calls.push(this.props.meldItems({
      query: descriptionQ,
      type: "order-resolutions",
      typeAlias: TYPE_DESCRIPTION,
      epoc: epoc
    }));    

    let statusQ = deepCopy(periodQuery);
    statusQ.fields = ["id","bookId","createdAt","status"];
    statusQ.calcs  = [`sum.${pfc.qtyField}`];
    statusQ.group  = ["status"];
    calls.push(this.props.meldItems({
      query: statusQ,
      type: "order-resolutions",
      typeAlias: TYPE_STATUS,
      epoc: epoc
    }));

    let salesConfig = deepCopy(ORDER_ITEM_SEARCH);
    let salesQ = configToNestedQuery(salesConfig,[
      {deleted:{neq:1}},
      rangeCondition ? {"mainOrder.createdAt":rangeCondition} : null // Sales for the given period 
    ]);
    salesQ.fields = ["id","bookId","orderId","itemId","item.sku","item.id","item.bookId","order.id","order.createdAt","order.bookId"];
    salesQ.calcs  = ["sum.quantity"];
    salesQ.group  = ["item.id"];
    calls.push(this.props.meldItems({
      query: salesQ,
      type: "order-items",
      typeAlias: TYPE_SALES,
      epoc: epoc
    }));  

    Promise.allSettled(calls).then((results) => {
      if (throttle) { 
        console.log("Throttle done");
        setTimeout(()=>{throttle.done();},100)
      }
      console.log('All promises have settled:', epoc);
    });
  }

  onExpand(evt, history) {
    let expanded = !this.state.expanded;
    if (this.props.onExpand) {
      this.props.onExpand(expanded);
    }
    this.setState({expanded:expanded});
  }

  genMeticDiv(name, count, key="foo") {
    let status = this.props.skusList.status || "loading";
    return <table className="Card StickyHeader" key={`metrics${key}`} animate={status}>
      <thead>
        <tr key="metrics-1">
          <th className="Name">{name}</th>
        </tr>
      </thead>
      <tbody>
        <tr key="metrics-1">
          <td className="Count">{count}</td>
        </tr>
      </tbody>
    </table>
  }

  renderMetrics() {
    let tskus = this.props.totalSkus;
    let tsales = this.props.totalSales;
    let trefunds = this.props.totalRefunds;
    return (<div className="StatTables MetricStats">
      {this.genMeticDiv("Total Resolutions", commafy(tskus), "TR")}
      {this.genMeticDiv("Total Sold", commafy(tsales), "TS")}
      {this.genMeticDiv("Total Resolutions Rate", tsales > 0 ? percentify(tskus/tsales) : "-", "TRR")}
      {this.genMeticDiv("Total Refunded", commafy(trefunds), "TRF")}
      {this.genMeticDiv("Total Refunded Rate", tsales > 0 ? percentify(trefunds/tsales) : "-", "TRFR")}
    </div>);
  }

  renderSkuStats() {
    let stats = this.props.skus;
    let salesBySku = this.props.salesBySku;
    let status = this.props.skusList.status || "loading";
    let total = 0;
    let totalSold = 0;
    let props = this.props;
    let resultSkusQty = props.resultSkusQty;
    let resultSkuSales = props.resultSkuSales;
    let tsalesN = props.totalSalesNarrow;
    let tskus = props.totalSkus;
    let tsales = props.totalSales;
    let pfc = this.state.productFieldConfig;


    // Generate the stats per row
    let fstats = stats.map((doc)=>{
      let data = doc.data;
      let name = dot(data,pfc.skuField);
      let count = parseInt(dot(data,pfc.sumField)) || 0
      let sold = salesBySku[name] || 0;
      total += count;
      totalSold += sold;
      return {
        name: name,
        count: count,
        sold: sold,
        resolutionRate: sold > 0 ? (count/sold) : null,
        subResolutionsPercent: resultSkusQty > 0 ? (count/resultSkusQty) : null,
        subSalesPercent: resultSkuSales > 0 ? (sold/resultSkuSales) : null,
        subRatePercent: resultSkuSales > 0 ? (count/resultSkuSales) : null,
        salesRate: tsales > 0 ? (count/tsales) : null
      }
    });

    // Check if sorting is needed
    let srt = this.state[`sortsku`];
    if (srt) { this.sortDataArray(fstats, srt.col, srt.asc);}
    return (<table className="Card StickyHeader" key={"sku-stats"} animate={status}>
      <thead>
        <tr>
          <th>{pfc.name}</th>
          <th className="Period" colSpan="3">Period</th>
          <th className="Subset" colSpan="3">Subset</th>
        </tr>
        <tr>
          <th className="Name" {...this.genSortProps("sku","name")}>SKU <FontAwesomeIcon icon={["far","sort-up"]}/></th>

          <th className="Count" {...this.genSortProps("sku","count")}>Resolutions<FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Sold" {...this.genSortProps("sku","sold")}>Units Sold<FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate" {...this.genSortProps("sku","resolutionRate")} info="Resolutions / Units Sold">
            Rate <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>

          <th className="Rate" {...this.genSortProps("sku","subResolutionsPercent")} info="Resolutions / Total Resolutions">
            Resolutions % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
          <th className="Rate" {...this.genSortProps("sku","subSalesPercent")} info="Units Sold / Total Units Sold">
            Sold % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
          <th className="Rate" {...this.genSortProps("sku","subSalesPercent")}  info="Resolutions / Total Units Sold">
            Rate % <FontAwesomeIcon icon={["far","sort-up"]}/>
          </th>
        </tr>
      </thead>
      <tbody>
      {fstats.map((data, index)=>{
        return (<tr key={`stats-${index}`} data-blank={!data.name}>
          <td className="Name">{data.name || "–"}</td>
          <td className="Count">{commafy(data.count)}</td>
          <td className="Sold">{commafy(data.sold)}</td>
          <td className="Rate">{percentify(data.resolutionRate,"-")}</td>
          <td className="Rate">{percentify(data.subResolutionsPercent,"-")}</td>
          <td className="Rate">{percentify(data.subSalesPercent,"-")}</td>
          <td className="Rate">{percentify(data.subRatePercent,"-")}</td>
        </tr>);
      })}
      </tbody>
      <tfoot>
        <tr>
          <td className="Name">Total</td>
          <td className="Count">{commafy(total)}</td>
          <td className="Sold">{commafy(totalSold)}</td>
          <td className="Rate">{totalSold > 0 ? percentify(total/totalSold) : "-"}</td>
        </tr>
      </tfoot>
    </table>);
  }

  renderStats(type, field, title, fieldmap) {
    let props = this.props;
    let stats = dot(props.inventory,[type,"list","docs"]) || [];
    let status = dot(props.inventory,[type,"list","status"]) || "loading";
    let tskusN = props.resultSkusQty;
    let tsalesN = props.totalSalesNarrow;
    let tskus = props.totalSkus;
    let tsales = props.totalSales;
    let total = 0;
    let pfc = this.state.productFieldConfig;
    if (!stats) { return null; }

    // Generate the stats per row
    let fstats = stats.map((doc)=>{
      let data = doc.data;
      let value = dot(data,field);
      let name = fieldmap ? fieldmap[value] : dot(value);
      let count = parseInt(dot(data,pfc.sumField)) || 0;
      //let count = parseInt(dot(data,"calcs.sumQuantity")) || 0;
      total += count;
      return {
        name: fieldmap ? fieldmap[value] : value,
        value: value,
        count: count,
        subResolutionsPercent: tskusN > 0 ? (count/tskusN) : null,
        subSalesPercent: tsalesN > 0 ? (count/tsalesN) : null,
        subRatePercent: tskus > 0 ? (count/tskus) : null,
        salesRate: tsales > 0 ? (count/tsales) : null
      }
    });

    // Check if sorting is needed
    let srt = this.state[`sort${field}`];
    if (srt) { this.sortDataArray(fstats, srt.col, srt.asc); }

    return (<table className="Card StickyHeader" key={`${type}-stats`} animate={status}>
      <thead>
        <tr>
          <th className="Name"  {...this.genSortProps(field,"name")}>{title} <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Count" {...this.genSortProps(field,"count")}>Logged <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"subResolutionsPercent")}>Subset Logged % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"subSalesPercent")}>Subset Sold % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"subRatePercent")}>Total Logged % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
          <th className="Rate"  {...this.genSortProps(field,"salesRate")}>Total Sold % <FontAwesomeIcon icon={["far","sort-up"]}/></th>
        </tr>
      </thead>
      <tbody>
      {fstats.map((data, index)=>{
        return (<tr key={`stats-${index}`}>
          <td className="Name" data-hype={fieldmap ? data.value : ""}>{data.name || "–"}</td>
          <td className="Count">{commafy(data.count)}</td>
          <td className="Rate">{percentify(data.subResolutionsPercent, "-")}</td>
          <td className="Rate">{percentify(data.subSalesPercent, "-")}</td>
          <td className="Rate">{percentify(data.subRatePercent, "-")}</td>
          <td className="Rate">{percentify(data.salesRate, "-")}</td>
        </tr>);
      })}
      </tbody>
    </table>);
  }

  genSortProps(table,col) {
    let srt = this.state[`sort${table}`] || {};
    return {
      onClick: ()=>{this.toggleSort(table,col)},
      'data-sort': srt.col == col ? (srt.asc ? 'asc' : 'desc') : undefined
    }
  }

  toggleSort(table, col) {
    let handle = `sort${table}`;
    let srt = this.state[handle] || {};
    if (srt.col == col) {
      this.setState({[handle]:{col:col, asc:!srt.asc}});
    }
    else {
      this.setState({[handle]:{col:col, asc:true}});
    }
  }

  sortDataArray(arr, col, asc = true) {
    arr.sort((a,b)=>{
      if (a[col] < b[col]) {
        return asc ? -1 : 1;
      }
      else if ((a[col] > b[col])) {
        return asc ? 1 : -1;
      }
      return 0;
    });
  }

  sortDotDataArray(arr, col, asc = true) {
    arr.sort((a,b)=>{
      let _av = dot(a,col);
      let _bv = dot(b,col);
      if ( _av < _bv ) {
        return asc ? -1 : 1;
      }
      else if (_av > _bv) {
        return asc ? 1 : -1;
      }
      return 0;
    });
  }

  render() {
    let props = this.props;

    // Get the range to layout
    let productField = this.state.productField || RESOLUTION_PRODUCT_FIELDS[0];

    // Get the docs
    return (
      <div className="Page SearchPage ResolutionStatsPage">
        <PageHeaderOutline title={this.props.title || "Resolutions"} top={this._backButton()}>
          <select className="ProductFieldSelector" defaultValue={productField.value} onChange={(e)=>{this.onProductFieldChange(e.target.value)}}>
            {RESOLUTION_PRODUCT_FIELDS.map((v,i)=>{return <option key={`pf${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </PageHeaderOutline>
        <Section className="SearchSection" expanded={this.state.expanded}>
          <PaddedArea className="QuerySubsection">
            <QueryLine type="order-resolutions" typeAlias={TYPE_ALIAS} throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QuerySummaryLine type="order-resolutions" typeAlias={TYPE_ALIAS} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QueryOptionsPresentation className="Settings" type="order-resolutions" typeAlias={TYPE_ALIAS} throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          </PaddedArea>
        </Section>
        <div className="StatTables NarrowStats">
          {this.renderStats(TYPE_ACTIONS,'action',"Actions",RESOLUTION_ACTIONS_MAP)}
          {this.renderStats(TYPE_REASON,'reason',"Reasons",RESOLUTION_REASONS_MAP)}
          {this.renderStats(TYPE_DESCRIPTION,'description',"Descriptions")}
          {this.renderSkuStats()}
          {this.renderStats(TYPE_STATUS,'status',"Status",RESOLUTION_STATES_MAP)}
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let salesBySku = {};
  let totalSales = 0;
  let sales = dot(state.inventory,[TYPE_SALES,"list","docs"]) || [];
  for (var i = 0; i < sales.length; i++) {
    let stats = sales[i];
    let sku = dot(stats,"data.item.data.sku");
    let qty = parseInt(dot(stats,"data.calcs.sumQuantity")) || 0;
    if (sku) { salesBySku[sku] = qty; }
    totalSales += qty;
  }
  
  let skusList = dot(state.inventory,[TYPE_SKUS,"list"]) || {};
  let skus = skusList.docs || [];
  let resultSkusQty = 0;
  let resultSkuSales = 0;
  for (var i = 0; i < skus.length; i++) {
    let sku = dot(skus[i],"data.item.data.sku") || "unknown";
    let qty = parseInt(dot(skus[i],"data.calcs.sumQuantity")) || 0;
    resultSkusQty += qty;
    resultSkuSales += salesBySku[sku] || 0; 
  }

  let fullList = dot(state.inventory,[TYPE_FULL,"list"]) || {};
  let full = fullList.docs || [];
  let totalSkus = 0;
  let totalSalesNarrow = 0;
  for (var i = 0; i < full.length; i++) {
    let sku = dot(full[i],"data.item.data.sku");
    totalSalesNarrow += sku ? (salesBySku[sku] || 0) : 0;
    totalSkus += parseInt(dot(full[i],"data.calcs.sumQuantity")) || 0;
  }

  return {
    skus: skus,
    skusList: skusList,
    resultSkusQty: resultSkusQty,
    resultSkuSales: resultSkuSales,
    query: dot(state.inventory,[TYPE_ALIAS,"list","query"]) || {},
    sales: sales,
    salesBySku: salesBySku,
    totalSales: totalSales,
    totalSalesNarrow: totalSalesNarrow,
    fullList: fullList,
    totalSkus: totalSkus,
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    queryItems: opts => dispatch(queryItems(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    loadItem: opts => dispatch(loadItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ResolutionStatsPage)
