import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import TableSearchSection from '../wrappers/TableSearchSection';

import { PRODUCT_SEARCH, PRODUCT_TYPES } from '../../constants/inventory/Product'
import { dot, deepCopy } from '../../lib/obj';

import './Page.css';
import './ProductsPage.css';

// The max number of produts to list
const PRODUCT_LIST_LIMIT = 50;
const LOCO_PRODUCT_SEARCH = deepCopy(PRODUCT_SEARCH);

export const USER_VIEWS = [
  { value:"view",  name: "View",  layout: "Read only view"},
  { value:"edit",  name: "Edit",  desc: "Edit products view"}
];

class ProductsPage extends Page {

  onView(evt, history, item) {
    if (item) {
      history.push(`/products/${item.data.id}`);
    }
  }

  render() {
    // Get the docs
    return (
      <div className="Page ProductsPage">
        <PageHeaderOutline title={this.props.title || "Products"} top={this._backButton()}>
        </PageHeaderOutline>
        <TableSearchSection type="products" controllable="true"
        query={LOCO_PRODUCT_SEARCH}
        onAddItem={(f)=>{f({type:"part"})}}
        layout={[
          {title:"",        field:"image", type:"image", className:"Icon", saveField: "imageId"},
          {title:"ID",      field:"id",   type: "action", onClick:(e,h,i)=>{this.onView(e,h,i)}},
          {title:"Type",    field:"type", type: "dropdown", options: PRODUCT_TYPES, className:"Type"},
          {title:"Name",    field:"name", type: "string", onClick:(e,h,i)=>{this.onView(e,h,i)}},
          {title:"SKU",     field:"sku",  type: "string", className:"SKU", hype:"whisper" },
          {title:" ",       field:"description",  type: "text", className:"Description"},
        ]}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = dot(state.inventory,"products.list") || {};
  return {
    list: list,
    products: list.docs || []
  }
};

const mapDispatch = (dispatch) => { return {} };

export default connect(
  mapState,
  mapDispatch
)(ProductsPage)
