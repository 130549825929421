import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from './Page'

import BareInput from '../elements/BareInput';
import CheckBoxVal from '../containers/CheckBoxVal';
import DateVal from '../containers/DateVal';
import Draft from '../../lib/draft';
import ModelLayout from '../layouts/ModelLayout';
import ModelSearchSection from '../sections/ModelSearchSection';
import PaddedArea from '../areas/PaddedArea';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import ProductLayout from '../layouts/ProductLayout';
import QueryLine from '../layouts/QueryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import RectButton from '../elements/RectButton';
import Section from '../elements/Section';
import Separator from '../elements/Separator';

import TableSearchSection from '../wrappers/TableSearchSection';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { deleteItem, loadItem, meldItems, startDraftItem, purgeItem, pushItem, setItemField } from '../../actions/InventoryActions';
import { dot, reg, deepCopy, mapk2k, mappify, sfc } from '../../lib/obj';
import { _ } from '../../lib/underscore';
import { configToNestedQuery } from '../../lib/inventory-util';
import { commafy, money } from '../../lib/formats';
import { openPopup, closePopup } from '../../actions/PopupActions';

import './Page.css';
import './SearchPage.scss';
import './BuildPage.scss';

import { BUILD_ITEM_SEARCH, BUILD_STATUS } from '../../constants/inventory/Build';
import { PRODUCT_SEARCH } from '../../constants/inventory/Product';

const STATUS_WORKING = {
  creating: true,
  saving: true,
  deleting: true
}

const DEFAULT_BUILD_DATA = {
  status: BUILD_STATUS[0].value
};

class BuildPage extends Page {

  constructor() {
    super();
  }

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      this.props.loadItem({type:"builds",id:id});
    }
  }

  onItemsLoaded(items) {
    if (items.length > 0) {
      this.loadTotalReceivedStock(items);
    }
  }

  loadTotalReceivedStock(items, onDone) {
    let query = {
      links:["transfer"],
      fields: ["bookId","id","itemId","transfer.id","transfer.bookId"],
      group:['itemId'],
      where: [
        {deleted: {neq: 1}},
        {'transfer.deleted': {neq: 1}},
        {'transfer.sourceId': {null: true}},
        {or: this.genItemsCondition(items)},
        {or:[{"transfer.status":{eq:"sent"}},{"transfer.status":{eq:"received"}}]}
      ],
      calcs: [
        "sum.quantity"
      ]
    };

    this.props.meldItems({
      type: "transfer-items",
      typeAlias: "builds",
      idAlias: this.props.id,
      childTypeAlias: "stock",
      query: query
    }).then(()=>{
      if (onDone) { onDone(); }
    });
  }

  genItemsCondition(items) {
    return items.map((item) => {
      return {itemId:{eq:item.data.itemId}};
    });
  }

  onAddItem() {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select products to Add",
        type: "products",
        typeAlias: "products-parts",
        limit: 50,
        query: deepCopy(PRODUCT_SEARCH),
        conditions: [{id:{neq:this.props.id}}],
        renderItem: (item, index, optionProps)=>{
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        onOk:(items)=>{
          this._addItems(items)
        }
      }
    });
  }

  _addItems(items) {
    let id = this.props.id;
    items.forEach((item, index)=>{
      let data = item.data || {};
      this.props.startDraftItem({
        type:"builds",
        id: id,
        childType: "items",
        index: -1,
        data: {
          buildId: id,
          itemId: data.id,
          item: item
        }
      });
      setTimeout(()=>{ this.loadTotalReceivedStock(this.props.items); },250);
    });
  }

  onDeleteItem(e, item, index) {
    let id = dot(item,"data.id");
    let opts = {type:"builds",id:dot(item,"data.buildId"), childType: "items", childId: id, index: index};
    if (id > 0) {
      this.props.deleteItem(opts).then(()=>{this.props.purgeItem(opts);});
    }
    else {
      this.props.purgeItem(opts);
    }
  }

  onExpand(evt, history) {
    this.setState({expanding: true});

    // Map of components by parent id
    const memo = {};

    // Define helper function to help us get the batches of products
    const sfetch = (bis)=> {
      let fis = bis.filter((bi)=>{
        return bi.data.type != "part" && !memo[bi.data.id];
      });

      if (fis.length == 0) {
        console.log("MEMO: ", memo);
        this.buildDependentItems(memo);
        this.setState({expanding: false});
        setTimeout(()=>{ this.loadTotalReceivedStock(this.props.items); },250);
        return; /* Done */
      }

      //Fetch items
      let bisConditions = fis.map((bi)=>{return {productId:{eq:bi.data.id}}});
      this.props.meldItems({
        type: "product-items",
        typeAlias: "builds",
        idAlias: this.props.id,
        childTypeAlias: "components",
        query: {
          links: ["product","item","image"],
          where: [
            {deleted:{neq: 1}},
            {type:{eq:"product"}},
            {or:bisConditions},
          ]
        }
      }).then((res)=>{

        // Handle to the next set of products to query
        let ptqById = {};

        // Update memorization
        res.productItems.forEach((pi, i) => {
          let comps = reg(memo, pi.data.productId,[]);
          comps.push(pi);
          let pitem = pi.data.item;
          dot(pitem,"data.image",pi.data.image);
          ptqById[pi.data.itemId] = pitem;
        });

        console.log("res: ", ptqById);
        res.productItems.forEach((item, i) => {
          console.log(` [${item.data.item.data.type}]   ${item.data.item.data.name}: ${item.data.quantity}`)
        });

        // Continue to get more
        sfetch(_.values(ptqById));
      });
    }

    // Get handle to the primary products
    let pis = this.props.items.filter((i)=>!i.data.dependent).map((i)=>i.data.item);
    sfetch(pis);
  }

  buildDependentItems(memo) {

    // Handle to the dependents by ID
    const dbi = {};

    const buildDependents = (prd, qty = 1) => {
      if (!prd) { return; }
      // Get the childeren
      let childs = memo[prd.data.id];
      if (!childs) { return; }

      childs.forEach((pi, i) => {
        console.log(`Tally product: ${pi.data.product.data.name}`);
        let tally = reg(dbi,pi.data.itemId, {
          quantity: 0,
          product: pi.data.item
        });

        // Set the product quantity
        let ptqy = (qty * pi.data.quantity);
        tally.quantity += ptqy;

        // product item is apart
        if (dot(pi,"data.product.data.type") == "part") {
          return;
        }

        buildDependents(pi.data.item, ptqy);
      });
    }

    // Start by getting dependents for primary products
    this.props.items.forEach((bi, i) => {
      if (bi.data.dependent) {return;}
      buildDependents(bi.data.item, bi.data.quantity);
    });

    let dependents = _.values(dbi)
    dependents.forEach((d)=>{
      console.log(`--- ${d.product.data.name}: ${d.quantity}`)
    });

    // Create the build entries
    for (var i = dependents.length-1; i > -1; i--) {
      let d = dependents[i];
      let product = d.product;
      this.props.startDraftItem({
        type:"builds",
        id: this.props.id,
        childType: "items",
        index: -1,
        data: {
          buildId: this.props.id,
          itemId: product.data.id,
          item: product,
          quantity: d.quantity,
          dependent: true
        }
      });
    }
  }

  onViewProduct(e,h,p) {
    h.push(`/products/${p.data.id}`);
  }

  saveField(field, value, index) {
    this.softEditField(field, value, index);
    let draft = Draft.deep(dot(this.props.build,["items","list","docs",index,"data"]));
    draft.set(field, value);
    this.props.pushItem({
      type: "builds", id: this.props.id, childType: "items", childId: draft.val("id"), index: index,
      docs: { buildItem: draft.updates },
      query: { links: "item,image", promptError:true }
    });
  }

  softEditField(field, value, index) {
    this.props.setItemField({ type:"builds", id:this.props.id, childType:"items", index: index, field: field, value: value });
  }

  saveTextField(field, value, orig, index) {
    if (value == orig || (value == "" && orig == null)) { return; }
    this.saveField(field, value, index);
  }

  genControlsDiv(item, index) {
    let data = item.data || {};
    let softActions;
    if (data.id > 0) {
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="ControlIcon" icon={["fal","ellipsis-v"]}/>
        </summary>
        <div className="DetailsList">
          <div className="Header"><h4>Actions</h4><aside>{index+1}</aside></div>
          <Separator collapsed={true}/>
          <ModelLayout>
            <div className="Name">{data.name || "..."}</div>
            <div className="Label">ID: {data.id}</div>
            <DateVal className="Sublabel">{data.createdAt}</DateVal>
          </ModelLayout>
          <Separator collapsed={true}/>
          <RectButton working={item.status == "deleting"} theme="red" onClick={(e,history)=>{this.onDeleteItem(e, item, index)}}>Delete</RectButton>
        </div>
      </details>
    } else {
      return <FontAwesomeIcon className="ControlIcon" icon={["far","times"]} onClick={(e)=>{this.onDeleteItem(e, item, index)}}/>
    }
  }

  genSelectsDiv() {
    let items = this.props.items;
    let selections = items.filter((i)=>this.state[`select${dot(i,"data.item.data.id")}`]);
    if (selections.length > 0) {
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="ControlIcon" icon={["far","square"]} />
        </summary>
        <div className="DetailsList">
          <div className="IconAction" onClick={()=>{this.setItemsSelect(selections,false)}}>
            <FontAwesomeIcon icon={["far","square-minus"]} /> Deselect All
          </div>
          <Separator collapsed={true}/>
          <div className="IconAction" onClick={()=>{}}>
            <FontAwesomeIcon icon={["fa","money-check-dollar"]} /> Create PO
          </div>
          <div className="IconAction" onClick={()=>{}}>
            <FontAwesomeIcon icon={["fas","truck"]} /> Create Transfer
          </div>
        </div>
      </details>
    }
    else {
      return <details className="FloatDetails">
        <summary>
          <FontAwesomeIcon className="ControlIcon" icon={["far","square"]} />
        </summary>
        <div className="DetailsList">
          <div className="IconAction" onClick={()=>{this.setItemsSelect(items,true)}}>
            <FontAwesomeIcon icon={["far","square-plus"]} /> Select All
          </div>
        </div>
      </details>
    }
  }

  setItemsSelect(items, select=false) {
    let nuState = {}
    items.forEach((item, i) => {
      nuState[`select${dot(item,"data.item.data.id")}`] = select
    });
    this.setState(nuState);
  }

  renderHeader() {
    return <tr>
      <th className="Controls"><FontAwesomeIcon className="ControlIcon" icon={["fal","plus"]} onClick={(e)=>{this.onAddItem(e)}}/></th>
      <th className="Check">{this.genSelectsDiv()}</th>
      <th className="Icon"></th>
      <th className="Product">Product</th>
      <th className="Stock">Stock</th>
      <th className="Quantity">Quantity</th>
      <th className="PO">PO</th>
      <th className="Ordered">Ordered</th>
      <th className="ETA">ETA</th>
    </tr>;
  }

  renderItem(item, index) {
    let state = this.state;
    let data = item.data;
    let stockById = this.props.stockById;
    let imgSrc = dot(item,"data.image.data.presignedS3GetUrl") || dot(item,"data.item.data.image.data.presignedS3GetUrl");
    let selectKey = `select${dot(data,"item.data.id")}`;
    let selected = state[selectKey];
    return <tr key={`item${index}-id${data.id || "0"}-${dot(data,"item.data.id") || "0"}-${data.version}`}
      data-deleted={data.deleted}
      data-working={STATUS_WORKING[item.status]}
      data-creating={data.id == null}
      data-dependent={data.dependent}
      data-selected={selected}
      >
      <td className="Controls">
        {this.genControlsDiv(item, index)}
      </td>
      <td className="Check" onClick={(e)=>{this.setState({[selectKey]:!selected})}}>
        <CheckBoxVal selected={selected}/>
      </td>
      <td className="Icon">
        <img className="ImageMini" src={imgSrc}/>
      </td>
      <td className="Product">
        <ProductLayout key="product" product={data.item} onClick={(e,h)=>this.onViewProduct(e,h,data.item)}/>
      </td>
      <td className="Stock" data-hype="whisper">
       { dot(stockById,[data.itemId,"data","calcs","sumQuantity"]) || "-" }
      </td>
      <td className="Quantity">
        <BareInput defaultValue={ data.quantity } type="number" onBlurOrEnter={(e)=>{this.saveTextField("quantity", e.target.value, data.id > 0 ? data.quantity : null, index)}}/>
      </td>
      <td className="PO">
        12332
      </td>
      <td className="Ordered">
        Jan 3
      </td>
      <td className="ETA">
        Jan 22
      </td>
    </tr>
  }

  render() {
    let props = this.props;
    const expandButton = <RectButton theme="green" working={this.state.expanding} onClick={(evt,history)=>{this.onExpand(evt, history)}}>
      {this.state.expanding ? "Expanding" : "Expand"}
    </RectButton>;
    return (
      <div className="Page SearchPage BuildPage">
        <PageHeaderOutline title={props.buildName} aside={expandButton} top={this._backButton()}>
        </PageHeaderOutline>
       
        <TableSearchSection label="Builds" className="Build" controllable="true" checkable="true"
        type="build-items"
        typeAlias="builds"
        idAlias={props.id}
        childTypeAlias="items"
        query={BUILD_ITEM_SEARCH}
        conditions={[{buildId:{eq:this.props.id}}]}
        reload={true}
        onQuerySuccess={(items)=>{this.onItemsLoaded(items)}}
        onAddItem={(f)=>{ this.onAdd() }}
        layout={[
          {title:"Created",     field:"createdAt", type:"date", readonly:true },
          {title:"ID",          field:"id", type: "action", onClick:(e,h,i)=>{this.onView(e,h,i)}},
          {title:"",            field:"image", type:"image", className:"Icon"},
          {title:"Product",     type:"custom", render:(item, i)=>{return <ProductLayout key="product" product={item.data.item} onClick={(e,h)=>this.onViewProduct(e,h,item)}/>}, className:"Product"},
          {title:"Stock",       type:"custom",render:(item, i)=>{return <span>{dot(props.stockById[item.data.itemId],"data.calcs.sumQuantity") || "-"}</span>}, className:"Stock"},
          {title:"Quantity",    field:"quantity", type:"number", className:"Quantity"},
        ]}/>
     
      </div>
    )
  }
}

const mapState = (state, props) => {
  let build = dot(state.inventory,["builds",props.id]) || {};
  let stock = dot(build,"stock.list.docs") || []
  let stockById = mappify(stock,(v)=>v.data.itemId);
  let items = dot(build,"items.list.docs") || [];
  return {
    buildName: dot(build,"data.name"),
    build: build,
    stockById: stockById,
    items: items
  }
};

const mapDispatch = (dispatch) => {
  return {
    deleteItem: opts => dispatch(deleteItem(opts)),
    loadItem: opts => dispatch(loadItem(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
  }
};

export default connect(
  mapState,
  mapDispatch
)(BuildPage)
