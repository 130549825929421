export const DATERANGE_OPTIONS = [
  { value:"custom", name: "Custom",        desc: "Custom count"},
  { value:"today",  name: "Today",         desc: "Today's count"},
  { value:"days7",  name: "Last 7 days",   desc: "Week's count"},
  { value:"days30", name: "Last 30 days",  desc: "Month's count"},
  { value:"days90", name: "Last 90 days",  desc: "Month's count"},
  { value:"week",   name: "This Week",     desc: "Week's count"},
  { value:"month",  name: "This Month",    desc: "Month's count"},
  { value:"year",   name: "This Year",     desc: "Month's count"}
]

export const PRODUCT_GROUP_OPTIONS = [
  { 
    name: "iPhone", 
    condition:{or:[{"item.sku":{begins:"mbc"}},{"item.sku":{begins:"ec1%"}}]},  
    desc: "iPhone products"
  },
  { 
    name: "Samsung", 
    condition:{or:[{"item.sku":{begins:"mbs2"}},{"item.sku":{begins:"es2%"}}]},  
    desc: "Samsung products"
  },
  { 
    name: "MultiCharger", 
    condition:{"item.sku":{begins:"mbqiml"}},  
    desc: "MultiCharger products"
  }
]; 