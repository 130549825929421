import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import TableSearchSection from '../wrappers/TableSearchSection';

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ORDER_SEARCH, ORDER_STATUSES, ORDER_ORIGINS } from '../../constants/inventory/Order'
import { dot } from '../../lib/obj';

import './Page.css';
import './OrdersPage.css';

class OrdersPage extends Page {


  onAdd(evt, history) {
    history.push("/orders/new");
  }

  
  onView(evt, history, item) {
    if (item) {
      history.push(`/orders/${item.data.id}`);
    }
  }

  render() {
    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add Order</RectButton>;

    // Get the docs
    return (
      <div className="Page OrdersPage">
        <PageHeaderOutline title="Orders" aside={addButton} top={this._backButton()}>
          <Link theme="blue" to="/order-stats"><FontAwesomeIcon icon={["far","chart-line"]} /> Stats</Link>
        </PageHeaderOutline>
        <TableSearchSection label="Orders" className="Orders" controllable="true"
        type="orders"
        query={ORDER_SEARCH}
        reload={true}
        onAddItem={(f)=>{ console.log("Foo"); }}
        layout={[
          {title:"Created",     field:"createdAt", type:"date" },
          {title:"ID",          field:"id", type: "action", onClick:(e,h,i)=>{this.onView(e,h,i)}},
          {title:"Number",      field:"name" },
          {title:"Email",       field:"email" },
          {title:"Status",      field:"fulfillmentStatus", type: "dropdown", options: ORDER_STATUSES },
          {title:"Origin",      field:"origin", type: "dropdown", options: ORDER_ORIGINS },
          {title:"Total",       field:"totalPrice", type:"money", className: "Money" },
          {title:" ",           field:"notes",  type: "text", className:"Description"},
        ]}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = dot(state.inventory,"orders.list") || {};
  return {
    list: list,
    status: list.status,
    orders: list.docs || []
  }
};

const mapDispatch = (dispatch) => {
  return { }
};

export default connect(
  mapState,
  mapDispatch
)(OrdersPage)
