import React from 'react';
import { connect } from 'react-redux';
import Layout from './Layout';
import DateRangeInput from '../elements/DateRangeInput';
import ClearableVal from '../containers/ClearableVal';
import './QuerySummaryLine.scss';

import { setItemQuery } from '../../actions/InventoryActions';
import { _ } from '../../lib/underscore';
import { dot, deepCopy } from '../../lib/obj';
import { stateForList } from '../../lib/inventory-util';

class QuerySummaryLine extends Layout {

  constructor(props) {
    super(props);
  }

  onFilterClear(filterIndex) {
    let props = this.props;
    let config = deepCopy(props.config);
    let filter = dot(config,["filters",filterIndex]) || {};
    filter.epoc = Date.now();
    let options = filter.options || [];
    for (var i = 0; i < options.length; i++) {
      options[i].selected = false;
    }
    this.onConfigChange(config);
  }

  onConfigChange(config) {

    // Make sure config offset is cleared
    delete config.offset;

    // Set the item query
    this.props.setItemQuery({
      type: this.props.type,
      typeAlias: this.props.typeAlias,
      id: this.props.id,
      idAlias: this.props.idAlias,
      childType: this.props.childType,
      childTypeAlias: this.props.childTypeAlias,
      query: config
    });

    // Report config chagne
    if (this.props.throttle) {
      if (!this._queryThrottle) {
        this._queryThrottle = _.enqueue((config) => {
          if (this.props.onConfigChange) {
            this.props.onConfigChange(config, this._queryThrottle);
          }
        }, this.props.timeout || 5000);
      }
      this._queryThrottle(config)
    }
    else {
      if (this.props.onConfigChange) {
        this.props.onConfigChange(config, this._queryThrottle);
      }
    }
  }

  onRangeStartChange(filterIndex, e) {
    let d = e.target.value ? new Date(e.target.value+" 00:00:00") : new Date();
    this.onRangeChange("startAt", filterIndex, d.srvstr({utc:true}));
  }

  onRangeEndChange(filterIndex, e) {
    let d = e.target.value ? new Date(e.target.value+" 00:00:00") : new Date();
    this.onRangeChange("endAt", filterIndex, d.addDays(1).srvstr({utc:true}));
  }

  onRangeChange(side, filterIndex, date) {
    let props = this.props;
    let config = deepCopy(props.config);
    let filter = dot(config,["filters",filterIndex]) || {};
    filter.epoc = Date.now();
    filter[side] = date;
    this.onConfigChange(config);
  }

  onFulllRangeChange(filterIndex, s) {
    console.log("onFulllRangeChange: ", s);
    let config = deepCopy(this.props.config);
    let filter = dot(config,["filters",filterIndex]) || {};
    filter.epoc = Date.now();
    filter.startAt = s.startAt;
    filter.endAt = s.endAt;
    this.onConfigChange(config);
  }

  renderDaterange(filter, filterIndex, options) {
    let selected = options[0];
    let ctn;
    switch (selected.value) {
      case "today":
      case "days7":
      case "days30":
      case "days90":
      case "week":
      case "month":
      case "year":
        ctn = <React.Fragment>
          {selected.name}
        </React.Fragment>
        break;
      default:
        ctn = <DateRangeInput defaultStartAt={ filter.startAt } defaultEndAt={ filter.endAt }  onEnter={(e, s)=>this.onFulllRangeChange(filterIndex, s)}/>
    }
    return <ClearableVal key={`filter${filterIndex}`} className={`Filter Filter${filterIndex}`} onClear={(evt)=>{this.onFilterClear(filterIndex);}}>
      <span className="FilterLabel">{filter.label}:</span>
      {ctn}
    </ClearableVal>
  }

  render() {
    let config = this.props.config || {};
    let filters = config.filters || [];
    let count = 0;
    let descriptions = filters.map((filter, filterIndex)=>{
      let options = (filter.options || []).filter(i=>i.selected);
      if (options.length == 0) { return; }
      count++;
      if (filter.type == "daterange") {
        return this.renderDaterange(filter, filterIndex, options);
      }
      else {
        let descriptions = options.map(i=>i.name);
        return <ClearableVal key={`filter${filterIndex}`} className={`Filter Filter${filterIndex}`} onClear={(evt)=>{this.onFilterClear(filterIndex);}}>
          <span className="FilterLabel">{filter.label}:</span>
          {descriptions.join(", ")}
        </ClearableVal>
      }
    });

    let className = this.props.className || "";
    return (
      <div ref="root" className={`QuerySummaryLine ${className} Line`} data-filters={count} data-filtered={count > 0}>
        {descriptions}
      </div>
    );
  }
}

const mapState = (state, props) => {
  let list = stateForList(state.inventory, props);
  return {
    config: dot(list,"query") || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    setItemQuery: opts => dispatch(setItemQuery(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(QuerySummaryLine);
