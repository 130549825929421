import { dot, arrpath, reg, hasKeys } from './obj';
import { kewordify } from './filter-util';
import { _ } from './underscore';
import './date-util';

const PREFIX_GROUP_MAP = {
  act: "actions",
  prt: "parts",
  prd: "products",
  por: "pos",
  srv: "services"
};

const TYPE_GROUP_MAP = {
  login: "logins",
  po: "pos",
  product: "products",
  service: "services"
};

const STRIP_REGX = /^\s+|\s+$/g;

/**
 * Gets the doc for the given id
**/
export const docForLoc = function(inventory, id=0) {
  if (inventory && id) {
    let prefix = id.toString().substring(0,3);
    let group = groupForPrefix(inventory, prefix);
    if (group) {
      return group[id];
    }
  }
  return undefined;
}

/**
 * Gets the doc options
**/
export const stateForDoc = function (state, opts) {
  let type = opts.typeAlias || opts.type;
  if (type) {
    let id = opts.idAlias || opts.id;
    let groupItem = dot(state,[type,id]);
    if (groupItem) {
      let childType = opts.childTypeAlias || opts.childType;
      if (childType) {
        if (opts.index !== undefined) {
          return dot(groupItem,[childType,"list","docs",opts.index]);
        }
        else {
          let childId = opts.childIdAlias || opts.childId;
          return dot(groupItem,[childType, childId]);
        }
      }
      else {
        return groupItem;
      }
    }
    return undefined;
  }
};

/**
 * Gets the list for the given state
**/
export const stateForList = function (state, opts) {
  let type = opts.typeAlias || opts.type;
  if (!type) { return; }

  let group = dot(state, type);
  if (!group) { return; }

  let id = opts.idAlias || opts.id;
  if (id) {
    let childType = opts.childTypeAlias || opts.childType;
    if (!childType) { return; }
    return dot(group,[id, childType, "list"]);
  }
  else {
    return group.list
  }
  return undefined;
};

/**
 * Gets the group for the given locator prefix
**/
export const groupForPrefix = function(inventory, prefix){
  let name = PREFIX_GROUP_MAP[prefix];
  return inventory && name ? inventory[name] : undefined;
}

/**
 * Gets the group for the given type
**/
export const groupNameForType = function(type){
  return TYPE_GROUP_MAP[type];
}

/**
 * Helps generate where conditions from filter
**/
export const queryToWhere = function(config = {}, where={}, {translate={}}={}){
  if (config.term) {
    let term = config.term;
    if (term.fields) {
      term.fields.forEach((field,vi)=>{
        field = translate[field] || field;
        where[`where-0-or${vi}-${field}-contains`] = term.value;
      });
    }
    else {
      let field = translate[term.field] || term.field || 'name';
      where[`where-${field}-contains`] = term.value;
    }
  }
  let filters = config.filters || [];
  filters.forEach((filter,fi)=>{
    let vals = filter.options.filter((option)=>{return option.selected});
    if (vals.length > 1) {
      vals.forEach((val,vi)=>{
        let field = translate[filter.field] || filter.field;
        where[`where-${fi+1}-or${vi}-${field}-eq`] = vals[vi].value;
      });
    }
    else if (vals.length == 1) {
      let field = translate[filter.field] || filter.field;
      where[`where-${field}-eq`] = vals[0].value;
    }
  });
  return where;
};



/**
 * Helps generate where conditions from condition map
 [
   {creature:{eq:"animal"}},
   {or:[{name:{eq:"cow"}},{name:{eq:"dog"}},{name:{eq:"duck"}}]},
   {or:[{id:{eq:"123"}},{id:{eq:"456"}},{id:{eq:"678"}}]}
 ]
 => {
    "where-creature-eq": "animal",
    "where-1-or0-name-eq": "cow",
    "where-1-or1-name-eq": "dog",
    "where-1-or2-name-eq": "duck",
    "where-2-or0-id-eq": "123",
    "where-2-or1-id-eq": "456",
    "where-2-or2-id-eq": "678",
  }
**/
export const conditionsToQuery = function(conditions, query={}, prefix=''){
  let len = conditions.length;
  conditions.forEach((condition, index)=>{
    if (condition) {
      if (condition.or) {
        conditionsToQuery(condition.or, query, `${index}-or`);
      }
      else if (condition.and) {
        conditionsToQuery(condition.and, query, `${index}-and`)
      }
      else {
        let arr = arrpath(condition, {depth:2});
        if (prefix) {
            query[`where-${prefix}${index}-${arr[0]}-${arr[1]}`] = dot(condition, arr);
        }
        else {
          query[`where-${arr[0]}-${arr[1]}`] = dot(condition, arr);
        }
      }
    }
  });
  return query;
};


/**
 * Helps generate where conditions from the filter config
**/
export const configToConditions = function(config = {}, conditions = [], {translate={}}={}){
  // Append any conditions in the config
  conditions = config.conditions ? config.conditions.concat(conditions) : conditions;

  // Build term conditions
  let term = config.term;
  if (term && term.value && term.value.length > 0) {
    let value = term.value;
    let fields = term.fields || (term.field ? [term.field] : null);
    let valmap = kewordify(value);
    let subs = [];
    if (term.keymap && valmap._length > 0) {
      let keys = _.keys(term.keymap);
      keys.forEach((k, i) => {
        let field = term.keymap[k];
        let kwv = valmap[k];
        if (kwv && kwv.length > 0) { kwv = kwv.replace(STRIP_REGX,""); }
        if (kwv && kwv.length > 0) { subs.push(keyConfigToCondition(field, kwv)); }
      });
      if (subs.length > 0 ) { 
        conditions.push(subs.length > 1 ? {and:subs} : subs[0]); 
      }
    }
    else if (fields && fields.length > 0) {
      value = value.replace(STRIP_REGX,"");
      fields.forEach((field,vi)=>{
        field = translate[field] || field;
        subs.push({[field]:{contains:value}});
      });
      if (subs.length > 0 ) { 
        conditions.push(subs.length > 1 ? {or:subs} : subs[0]); 
      }
    }
  }
  // Build filter conditions
  let filters = config.filters || [];
  filters.forEach((filter,fi)=>{
    let condition = filterToCondition(filter, translate);
    if (condition) {
      conditions.push(condition);
    }
  });
  return conditions;
};

/**
 * Converts the filter to a condition
**/
export const filterToCondition = function(filter, translate) {
  let vals = filter.options.filter((option)=>{return option.selected});

  if (vals.length == 0) { return; }

  if (filter.type == "daterange") {
    return dateRangeToCondition(filter, vals[0], translate);
  }

  let subs = [];
  vals.forEach((opt,vi)=>{
    let field = translate[filter.field] || filter.field;
    let condition = opt.condition ? opt.condition : {[field]:{eq:opt.value}};
    subs.push(condition);
  });

  return subs.length > 1 ? {or:subs} : subs[0];
}

/**
 * Converts the daterange filter to a condition
**/
export const dateRangeToCondition = function(filter, val, translate) {
  let field = translate[filter.field] || filter.field;
  const today = (new Date()).startOfDay();
  const tomorrow = today.tomorrow();
  let startAt, endAt;
  switch (val.value) {
    case "today":
      startAt = today.srvstr({utc:true});
      endAt = tomorrow.srvstr({utc:true});
      return {[field]:{"between":[startAt,endAt]}};
    case "days7":
      startAt = today.addDays(-7).srvstr({utc:true});
      endAt = tomorrow.srvstr({utc:true});
      return {[field]:{"between":[startAt,endAt]}};
    case "days30":
      startAt = today.addDays(-30).srvstr({utc:true});
      endAt = tomorrow.srvstr({utc:true});
      return {[field]:{"between":[startAt,endAt]}};
    case "days90":
      startAt = today.addDays(-90).srvstr({utc:true});
      endAt = tomorrow.srvstr({utc:true});
      return {[field]:{"between":[startAt,endAt]}};
    case "week":
      startAt = today.startOfWeek().srvstr({utc:true});
      endAt = tomorrow.srvstr({utc:true});
      return {[field]:{"between":[startAt,endAt]}};
    case "month":
      startAt = today.startOfMonth().srvstr({utc:true});
      endAt = tomorrow.srvstr({utc:true});
      return {[field]:{"between":[startAt,endAt]}};
    case "year":
      startAt = today.startOfYear().srvstr({utc:true});
      endAt = tomorrow.srvstr({utc:true});
      return {[field]:{"between":[startAt,endAt]}};
    default:
      startAt = filter.startAt ? new Date(filter.startAt+" 00:00:00") : null;
      endAt = filter.endAt ? new Date(filter.endAt+" 00:00:00") : null;
      if (startAt && endAt) {
        return {[field]:{
          "between":[
            startAt.srvstr({utc:true}),
            endAt.addDays(1).srvstr({utc:true})
          ]
        }};
      }
      if (startAt) {
        return {[field]:{"gt": startAt.srvstr({utc:true})}};
      }
      if (filter.endAt) {
        return {[field]:{"lt": endAt.addDays(1).srvstr({utc:true})}};
      }
  }
}

/**
 * Converts a key config with a keyword to a condition
**/
export const keyConfigToCondition = function(config, keyword) {
  let field = config.field || config;
  //let op = config.op || "contains";
  let op = config.op || "begins";
  let multi = keyword.split(",");
  if (multi.length > 1) {
    return {
      or: multi.map((kw)=>{
        kw = kw.replace(STRIP_REGX,"");
        return {[field]:{[op]:kw}};
      })
    }
  }
  else {
    return {[field]:{[op]:keyword}}
  }
}

/**
 * Helps generate where conditions from the filter config
**/
export const configToNestedQuery = function(config = {}, conditions = [], {translate={}}={}){
  let query = {where: configToConditions(config,conditions)};
  if (config.sort && config.sort.length > 0) {
    let term = config.sort.find(i=>i.selected);
    if (term) {
      query.order = term.field;
      query.direction = term.direction;
    }
  }
  if (config.links) {
    query.links = config.links;
  }
  if (config.limit > 0) {
    query.limit = config.limit;
  }
  if (config.group) {
    query.group = config.group;
  }
  if (config.calcs) {
    query.calcs = config.calcs;
  }
  return query;
};

/**
 * Checks to see if the given doc state has edits
**/
export const docHasEdits = function(doc) {
  return hasKeys(dot(doc,"sets")) ||
    (dot(doc,"action") == "delete" && dot(doc,"data.id")) ||
    (!dot(doc,"action") && !dot(doc,"data.id"));
}
