import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import ProductLayout from '../layouts/ProductLayout';
import DateVal from '../containers/DateVal';
import PaddedArea from '../areas/PaddedArea';
import QueryPaginatePresentation from '../presentations/QueryPaginatePresentation';
import Separator from '../elements/Separator';

import Input from '../elements/Input';
import BareInput from '../elements/BareInput';
import ModelLayout from '../layouts/ModelLayout';

import Section from '../elements/Section';
import QueryLine from '../layouts/QueryLine';
import QuerySummaryLine from '../layouts/QuerySummaryLine';
import QueryOptionsPresentation from '../presentations/QueryOptionsPresentation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Draft from '../../lib/draft';
import { openPopup, closePopup } from '../../actions/PopupActions';
import { startDraftItem, purgeDraftItem, setItemField } from '../../actions/InventoryActions';
import { loadItem, meldItems, setItemQuery, pushItem, deleteItem, purgeItem, meldDownload, meldCount } from '../../actions/InventoryActions';
import { setAppField, pushAppNotification } from '../../actions/AppActions';

import { dot, deepCopy, mapk2k, mappify, sfc, arrify } from '../../lib/obj';
import { configToNestedQuery } from '../../lib/inventory-util';
import { commafy, money, simplifyDate, pcnt } from '../../lib/formats';
import { mDownload } from '../../lib/file';

import { _ } from '../../lib/underscore';

import './Page.css';
import './SearchPage.scss';
import './ResolutionsPage.scss';
import  '../../lib/date-util';

import { PRODUCT_SEARCH } from '../../constants/inventory/Product';
import { ORDER_SEARCH } from '../../constants/inventory/Order';
import { RESOLUTION_SEARCH, RESOLUTION_STATES, RESOLUTION_REASONS, RESOLUTION_REASONS_MAP,
  RESOLUTION_CARRIERS, RESOLUTION_ACTIONS, RESOLUTION_ACTIONS_MAP, RESOLUTION_DESCRIPTIONS,
  RESOLUTION_DESCRIPTIONS_MAP, RESOLUTION_PRIORITIES, RESOLUTION_ORIGINS, RESOLUTION_PRIORITIES_MAP } from '../../constants/inventory/OrderResolution';

export const USER_VIEWS = [
  { value:"comprehensive",  name: "Comprehensive",  desc: "View all available information"},
  { value:"support",        name: "Support 1",      desc: "View information relevant to support"},
  { value:"shipping",       name: "Support 2",      desc: "View informatino relevant to shipping"}
];
export const USER_VIEWS_MAP = mapk2k(USER_VIEWS,"value","name")

const STATUS_WORKING = {
  creating: true,
  saving: true,
  deleting: true
}

const PAGE_LIMIT = 50;
const CUST_PRODUCT_SEARCH = deepCopy(PRODUCT_SEARCH);
dot(CUST_PRODUCT_SEARCH,"limit",PAGE_LIMIT);

const DEFAULT_RESOLUTION_DATA = {
  action: RESOLUTION_ACTIONS[0].value,
  reason: RESOLUTION_REASONS[0].value,
  carrier: RESOLUTION_CARRIERS[0].value,
  status: RESOLUTION_STATES[0].value,
  priority: RESOLUTION_PRIORITIES[0].value,
  origin: RESOLUTION_ORIGINS[0].value
};

const CSV_HEADERS = [
  "created",
  "origin",    
  "name",
  "original order",
  "original sku",
  "quantity",
  "action",
  "new sku",
  "new quantity",
  "product $",
  "ship $",
  "reason",
  "description",
  "priority",
  "new order",
  "status",
  "completed",
  "notes"
];

class ResolutionsPage extends Page {

  constructor() {
    super();
    this.state.notes = {};
    this.setResultsTableRef = (el) =>{ this.resultsTable = el; }
    this.setSearchRef = (el) =>{ this.searchSection = el; }
  }

  componentWillMount() {
    let props = this.props;
    let searchConfig = deepCopy(RESOLUTION_SEARCH);

    // Get search from query string
    const qparams = new URLSearchParams(window.location.search);
    searchConfig.term.value = qparams.get('search');

    props.setItemQuery({
      type: "order-resolutions",
      query: searchConfig
    });
    // Load resolutions
    this.loadResolutionsWithConfig(searchConfig);
  }

  componentDidMount() {
    // Monitors the page
    this.monitor = setInterval(()=>{
      const tableOffset = this.resultsTable.getBoundingClientRect();
      const searchOffset = this.searchSection.refs.root.getBoundingClientRect();
      let expand = (tableOffset.x - searchOffset.x ) <= -20;
      if (this.state.expand != expand) {
        this.props.setAppField("menu.left.expand",!expand);
        this.setState({expand:expand});
      }
    },500);
  }

  componentWillUnmount() {
    if (this.monitor) {
      clearInterval(this.monitor);
      delete this.monitor;
    }
  }

  onExpand(evt, history) {
    let expanded = !this.state.expanded;
    if (this.props.onExpand) {
      this.props.onExpand(expanded);
    }
    this.setState({expanded:expanded});
  }

  onDownload() {
    let config = this.props.query;
    this.props.meldDownload({
      type: "order-resolutions",
      typeAlias: "order-resolutions-download",
      query: configToNestedQuery(deepCopy(config),[{deleted:{neq:1}}])
    }).then((items)=>{
      let csvContent = items.map((item)=>{
        let data = item.data || {};
        return arrify({
          "created": dot(data,"createdAt"),
          "origin": dot(data,"origin"),
          "name": data.refName,
          "original order": dot(data,"order.data.name"),
          "original sku": dot(data,"item.data.sku"),
          "quantity": data.quantity,
          "action": RESOLUTION_ACTIONS_MAP[data.action],
          "new sku": dot(data,"newItem.data.sku"),
          "new quantity": data.newQuantity,
          "product $": money(data.feeAmount),
          "ship $": money(data.shipAmount),
          "reason": RESOLUTION_REASONS_MAP[data.reason],
          "description": data.description,
          "priority": RESOLUTION_PRIORITIES_MAP[data.priority],
          "new order": dot(data,"newOrder.data.name"),
          "status": data.status,
          "completed": sfc(data.completedAt,(o)=>o.toString()),
          "notes": data.notes
        },CSV_HEADERS).map((v)=>`"${v == null ? "" : v}"`).join(",");
      });
      csvContent.unshift(CSV_HEADERS.map((h)=>`"${h}"`).join(","));
      let fname = `Resolutions ${simplifyDate(new Date())}.csv`;
      mDownload(csvContent.join("\n"),fname,'text/csv;encoding:utf-8');
    });
  }

  // Get the query config
  onConfigChange(config, throttle) {
    // Load resolutions
    this.loadResolutionsWithConfig(config, throttle);
  }

  loadResolutionsWithConfig(config, throttle) {
    // Build the stats queries
    const conditions = [{deleted:{neq:1}}];
    let query = configToNestedQuery(deepCopy(config), conditions);
    query.limit = config.limit;
    query.offset = config.offset;
    this.props.meldItems({
      type: "order-resolutions",
      query: query
    }).then(()=>{
      if (throttle) { setTimeout(()=>{throttle.done();},100) }
    });
    this.setState({sci: null, notes: {}});

    // Send the stats query
    this.loadResolutionsMetrics(conditions);
  }

  loadResolutionsMetrics(conditions) {
    conditions = conditions || [{deleted:{neq:1}}];
    this.props.meldItems({
      type: "order-resolutions",
      typeAlias: "order-resolutions-status-metrics",
      query: {
        links: ["order","item","newOrder","newItem"],
        where: deepCopy(conditions),
        group: ["status"],
        calcs: ["count.id"]
      }
    });
  }

  onViewChange(value) {
    this.setState({view:value});
  }

  onShowLog(title, conditions) {
    this.props.openPopup({
      name:"ActionLog",
      props:{
        title: title || "Resolution Actions Log",
        type: "actions",
        typeAlias: "resolution-actions",
        limit: 100,
        ignoreDelete: true,
        conditions: [{subjectType: {eq:"orderResolution"}}].concat(conditions)
      }
    });
  }

  onAdd(evt) {
    this.setState({sci: null, notes: {}});
    this.props.startDraftItem({
      type:"order-resolutions",
      index: -1,
      data: deepCopy(DEFAULT_RESOLUTION_DATA)
    });
  }

  genMeticDiv(name, count, key="foo") {
    let status = this.props.metricsList.status || "loading";
    return <table className="Card StickyHeader" key={`metrics${key}`} animate={status}>
      <thead>
        <tr><th className="Name" data-hype={key}>{name}</th></tr>
      </thead>
      <tbody>
        <tr><td className="Count">{count}</td></tr>
      </tbody>
    </table>
  }

  renderMetrics() {
    let metricsList = this.props.metricsList;
    let total = 0;
    let map = mappify(metricsList.docs,(doc)=>{
      total += dot(doc,"data.calcs.countId") || 0;
      return dot(doc,"data.status") || "unknown";
    });
    let unknownDiv;
    if (map["unknown"]) {
      let doc = map["unknown"];
      unknownDiv = this.genMeticDiv("Unknown", commafy(dot(doc,"data.calcs.countId") || 0), "unknown");
    }
    return (<div className="StatTables MetricStats">
      {this.genMeticDiv("Total", commafy(total), "total")}
      {RESOLUTION_STATES.map((rs,i)=>{
        let doc = map[rs.value];
        return this.genMeticDiv(rs.name, commafy(dot(doc,"data.calcs.countId") || 0), rs.value);
      })}
      {unknownDiv}
    </div>);
  }

  onFilterAlike(item, index) {
    let cors = [];
    if (item.data.orderId) {
      cors.push({orderId:{eq:item.data.orderId}});
    }
    if (item.data.refName) {
      cors.push({refName:{eq:item.data.refName}});
    }
    if (cors.length == 0) { return; }
    this.props.meldItems({
      type: "order-resolutions",
      query: {
        links: ["order","item","newOrder","newItem"],
        limit: PAGE_LIMIT,
        where: [{deleted:{neq:1}},{or:cors}]
      }
    });
    this.setState({sci: null, notes: {}});
  }

  onCopyLink(item, index) {
    navigator.clipboard.writeText(`${window.location.href}?search=id:${item.data.id}`).then(()=>{
      /* clipboard successfully set */
      this.props.pushAppNotification("Copied link...",{timeout: 2000});
    }, function() {
      /* clipboard write failed */
    });
  }

  onCopyInfo(item, index) {
    navigator.clipboard.writeText(JSON.stringify({'order-resolution':item})).then(()=>{
      /* clipboard successfully set */
      this.props.pushAppNotification("Copied zero...",{timeout: 2000});
    }, function() {
      /* clipboard write failed */
    });
  }

  onPasteInfo(item, index) {
    navigator.permissions.query({
      name: "clipboard-read",
    }).then((permission)=>{
      if (permission.state === "denied") { throw new Error("Not allowed to read clipboard.");}
      navigator.clipboard.readText().then((text)=>{
        try {
          console.log("CLIP: ", text);
          let map = JSON.parse(text);
          let clip = map['order-resolution'] || map['order-return'];
          if (!clip) { return; }
          this.pasteItemFromClip(index,clip);
        }
        catch(e) {
          console.error("Paste info error: ", e);
        }
      });
    });
  }

  pasteItemFromClip(index, clip) {
    let data = clip.data || {};
    let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]));
    draft.val("createdAt", Date.srvtify(data.createdAt,{utc:true}));
    draft.val("refName",data.refName);
    draft.val("orderId",data.orderId);
    draft.val("itemId",data.itemId);
    draft.val("reason",data.reason);
    draft.val("description",data.description);
    draft.val("notes",data.notes);
    this.props.pushItem({
      type: "order-resolutions", id: draft.val("id"), index: index,
      docs: { orderResolution: draft.updates },
      query: {links: "order,item"}
    });
  }

  genControlsDiv(item, index) {
    let data = item.data || {};
    let softActions;
    if (data.id > 0) {
      softActions = <React.Fragment>
        <Separator collapsed={true}/>
        <div className="IconAction" onClick={()=>{this.onCopyLink(item, index)}}>
          <FontAwesomeIcon icon={["far","link"]} /> Copy Link
        </div>
        <div className="IconAction" onClick={()=>{this.onCopyInfo(item, index)}}>
          <FontAwesomeIcon icon={["far","clipboard"]} /> Copy Info
        </div>
        { (data.refName || data.orderId) &&
          <div className="IconAction" onClick={()=>{this.onFilterAlike(item, index)}}>
            <FontAwesomeIcon icon={["far","filter"]} /> Filter Alike
          </div>
        }
        <div className="IconAction" onClick={()=>{this.onShowLog(`Resolution ${data.id} Actions Log`,[{subjectId:{eq:data.id}}])}}>
          <FontAwesomeIcon icon={["far","square-list"]} /> Show Log
        </div>
      </React.Fragment>
    } else {
      softActions = <React.Fragment>
        <Separator collapsed={true}/>
        <div className="IconAction" onClick={()=>{this.onPasteInfo(item, index)}}>
          <FontAwesomeIcon icon={["far","paste"]} /> Paste Info
        </div>
      </React.Fragment>
    }
    return <details className="FloatDetails">
      <summary><FontAwesomeIcon className="ControlIcon" icon={["fal","ellipsis-v"]}/></summary>
      <div className="DetailsList">
        <div className="Header"><h4>Resolution Actions</h4><aside>{index+1}</aside></div>
        <Separator collapsed={true}/>
        <ModelLayout>
          <div className="Name">{data.refName || RESOLUTION_REASONS_MAP[data.reason]}</div>
          <div className="Label">ID: {data.id}</div>
          <div className="Sublabel">{simplifyDate(data.createdAt)}</div>
        </ModelLayout>
        {softActions}
        <Separator collapsed={true}/>
        <RectButton theme="bw" onClick={(e,history)=>{this.onDuplicateItem(e, index)}}>Duplicate</RectButton>
        <RectButton working={item.status == "deleting"} theme="red" onClick={(e,history)=>{this.onDeleteItem(e, item.data.id, index)}}>Delete</RectButton>
      </div>
    </details>
  }

  genOrderDiv(order, index, field) {
    if (order && order.data) {
      let shopId = order.data.externalId ? order.data.externalId.split("-")[1] : null;
      let shopUrl = `https://1lss-store.myshopify.com/admin/orders/${shopId}`;
      return <details className="FloatDetails">
        <summary>{ order.data.name }</summary>
        <div className="DetailsList">
          <h4><a className="ShopifyIcon" href={shopUrl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab","shopify"]} /></a>&nbsp;&nbsp; Order Info</h4>
          <ModelLayout>
            <div className="Name">{order.data.email}</div>
            <div className="Label">{order.data.name}</div>
            <DateVal className="Sublabel">{order.data.createdAt}</DateVal>
          </ModelLayout>
          <RectButton theme="blue" onClick={(e)=>this.onSelectOrder(index, order, field)}>Change</RectButton>
        </div>
      </details>
    } else {
      return <div className="BlankOrder" onClick={()=>this.onSelectOrder(index, null, field)}> &ndash; </div>
    }
  }

  genNewOrderDiv(item, index) {
    let order = item.data.newOrder;
    if (order && order.data) {
      let shopId = order.data.externalId ? order.data.externalId.split("-")[1] : null;
      let shopUrl = `https://1lss-store.myshopify.com/admin/orders/${shopId}`;
      return <details className="FloatDetails">
        <summary>{ item.data.newOrderName }</summary>
        <div className="DetailsList">
          <h4><a className="ShopifyIcon" href={shopUrl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab","shopify"]} /></a>&nbsp;&nbsp; Order Info</h4>
          <ModelLayout>
            <div className="Name">{order.data.email}</div>
            <div className="Label">{order.data.name}</div>
            <DateVal className="Sublabel">{order.data.createdAt}</DateVal>
          </ModelLayout>
          <RectButton theme="blue" onClick={(e)=>this.onSelectOrder(index, order, "newOrderId")}>Change</RectButton>
        </div>
      </details>
    } else {
      return <BareInput defaultValue={ item.data.newOrderName } onBlur={(e)=>{this.saveNewOrderNameField(e.target.value, item.data.newOrderName, index)}}/>;
    }
  }

  genProductDiv(product, index, field, order) {
    if (product) {
      let hype = !product.data.sku ? "error" : "";
      return <details className="FloatDetails">
        <summary><span data-hype={hype}>{ product.data.sku || `ID-${product.data.id}` }</span></summary>
        <div className="DetailsList">
          <h4>Product Info</h4>
          <ProductLayout key="product" product={product}/>
          <RectButton theme="blue" onClick={(e)=>this.onSelectProduct(index, product, field, order)}>Change</RectButton>
        </div>
      </details>
    } else {
      return <div className="BlankProduct" onClick={()=>this.onSelectProduct(index, null, field, order)}> &ndash; </div>
    }
  }

  genNotesDiv(item, index) {
    if (item) {
      let data = item.data;
      let notes = dot(this.state,['notes',index]);
      let edited = notes != null && notes != data.notes;
      return <details className="FloatDetails" open={this.state.sci == index && this.state.scf == "notes"}>
        <summary onClick={(e)=>this.onControlToggle(e, index, 'notes')}>
          <FontAwesomeIcon className="Icon" icon={["far","memo-pad"]} data-filled={data.notes ? true : false} />
        </summary>
        <div className="DetailsList">
          <h4>Order Resolution Notes</h4>
          <PlainButton className="CloseButton" theme="whisper" onClick={(e)=>this.onControlToggle(e, index, 'notes')}><FontAwesomeIcon className="ControlIcon" icon={["far","times"]}/></PlainButton>
          <textarea name="notes" style={{width:"100%",minHeight:"10em"}} defaultValue={data.notes} onChange={(e)=>this.onNotesChange(e,index)}></textarea>
          <RectButton theme="blue" lock={!edited} working={STATUS_WORKING[item.status]} onClick={(e)=>this.saveField("notes",notes,index)}>Save</RectButton>
        </div>
      </details>
    }
  }

  onNotesChange(e, index) {
    let notes = this.state.notes;
    notes[index] = e.target.value;
    this.setState({notes:notes});
  }

  saveField(field, value, index) {
    this.softEditField(field, value, index);
    let id = dot(this.props.resolutions,[index,"data","id"]);
    let data = deepCopy(dot(this.props.resolutions,[index,"data"]) || {});
    data[field] = value;
    this.props.pushItem({
      type: "order-resolutions",
      id: id,
      index: index,
      docs: {
        orderResolution: {sets:{[field]:value}, data:data}
      },
      query: {links: "order,item,newOrder,newItem"}
    });
  }

  saveStatusField(value, index) {
    this.softEditField("status", value, index);

    // Check if value changed
    let id = dot(this.props.resolutions,[index,"data","id"]);
    let data = deepCopy(dot(this.props.resolutions,[index,"data"]) || {});

    // Do nothing if no change
    if (value == data.status) { return; }

    // Create a draft for easy changes
    let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]));
    draft.val("status",value);

    // Check if status is being reverted
    if (value == "initiated" || value == "incomplete") {
      draft.clear("completedAt");
      draft.clear("completedById");
    }
    else {
      draft.set("completedAt",(new Date()).srvstr({utc:true}))
      draft.reg("completedById",dot(this.props,"user.id"));
    }

    // Push the changes
    this.props.pushItem({
      type: "order-resolutions", id: id, index: index,
      docs: { orderResolution: draft.updates },
      query: {links: "order,item,newOrder,newItem"}
    }).then(()=>{
      this.loadResolutionsMetrics();
    });
  }

  softEditField(field, value, index) {
    let id = dot(this.props.resolutions,[index,"data","id"]);
    this.props.setItemField({
      type:"order-resolutions",
      id:id,
      index: index,
      field:field,
      value:value
    });
  }

  saveTextField(field, value, orig, index) {
    if (value == orig || (value == "" && orig == null)) { return; }
    this.saveField(field, value, index);
  }

  saveDescriptionField(value, orig, index) {
    if (value == orig || (value == "" && orig == null)) { return; }
    let rdm = RESOLUTION_DESCRIPTIONS_MAP[value];

    if (rdm) {
      let reason = rdm.reason || "unknown";
      let action = rdm.action || "unknown";
      this.softEditField("description", value, index);
      let id = dot(this.props.resolutions,[index,"data","id"]);

      // Create draft for easy updates
      let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]));
      draft.val("description", value);
      draft.val("reason", reason);
      draft.val("action", action);

      // Push the changes
      this.props.pushItem({
        type: "order-resolutions", id: id, index: index,
        docs: { orderResolution: draft.updates },
        query: {links: "order,item,newOrder,newItem"}
      });
    }
  }

  saveNewOrderNameField(value, orig, index) {
    if (value == orig || (value == "" && orig == null)) { return; }
    let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]));
    if (draft.val("status") == "initiated" || draft.val("status") == "incomplete") {
      draft.val("status","complete");
      draft.val("completedAt",(new Date()).srvstr({utc:true}));
      draft.reg("completedById",dot(this.props,"user.id"));
    }
    draft.val("newOrderName",value);
    this.props.pushItem({
      type: "order-resolutions", id: draft.val("id"), index: index,
      docs: { orderResolution: draft.updates },
      query: {links: "order,item,newOrder,newItem"}
    });
  }

  onSelectProduct(index, selectedProduct, field, order) {
    let searchConfig = deepCopy(CUST_PRODUCT_SEARCH);
    searchConfig.term.value = "";

    // Run load helper
    let runLoad = (load) => {
      if (order) {
        this.loadOrderProductIds(order,(ids)=>{
          if (ids && ids.length > 0) { searchConfig.term.value = `id:${ids.join(",")}`; }
          load(searchConfig);
        });
      }
      else {
        load(searchConfig);
      }
    }

    // Open Popup
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select a Product",
        type: "products",
        typeAlias: "return-products",
        limit: 25,
        query: searchConfig,
        delayLoad: runLoad,
        radio: true,
        selections: selectedProduct ? {[selectedProduct.data.id]:{ordering:0,item:selectedProduct}} : undefined,
        renderItem: (item, index, optionProps)=>{
          optionProps.image = dot(item,"data.image") || {};
          return [
            <ProductLayout key="product" product={item}/>
          ]
        },
        onOk:(items)=>{
          if (!items.length) {return;}
          let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]));
          draft.val(field, items[0].data.id);
          let qtyField = field == "itemId" ? "quantity" : "newQuantity";
          draft.reg(qtyField, 1);
          this.props.pushItem({
            type: "order-resolutions", id: draft.val("id"), index: index,
            docs: { orderResolution: draft.updates },
            query: {links: "order,item,newOrder,newItem"}
          });
        }
      }
    });

  }

  loadOrderProductIds(order, onDone) {
    this.props.meldItems({
      type: "order-items",
      query: {
        links:["item"],
        group:['itemId'],
        where: [
          {deleted: {neq: 1}},
          {orderId: {eq: dot(order,"data.id")} }
        ]
      }
    }).then((res)=>{
      if (res.orderItems && onDone) {
        onDone(res.orderItems.map((oi)=>dot(oi,"data.item.data.id")));
      }
    });
  }

  onSelectOrder(index, selectedOrder, field) {
    this.props.openPopup({
      name:"Search",
      props:{
        title: "Select an Order",
        type: "orders",
        typeAlias: "resolution-orders",
        limit: 25,
        query: ORDER_SEARCH,
        radio: true,
        selections: selectedOrder ? {[selectedOrder.data.id]:{ordering:0,item:selectedOrder}} : undefined,
        renderItem: (item, index, optionProps)=>{
          return [
            <ModelLayout>
              <div className="Name">{item.data.email}</div>
              <div className="Label">{item.data.name}</div>
              <DateVal className="Sublabel">{item.data.createdAt}</DateVal>
            </ModelLayout>
          ]
        },
        onOk:(items)=>{
          if (!items.length) { return; }
          if (field == "newOrderId") {
            this.saveNewOrderNameField(items[0].data.name, null, index);
          }
          else {
            let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]));
            draft.val("orderId",items[0].data.id);
            draft.val("refName",items[0].data.customerName);
            this.props.pushItem({
              type: "order-resolutions", id: draft.val("id"), index: index,
              docs: { orderResolution: draft.updates },
              query: {links: "order,item,newOrder,newItem"}
            });
          }
        }
      }
    });
  }

  onControlToggle(e, index, field) {
    if (e) { e.preventDefault() };
    let sci = this.state.sci == index && this.state.scf == field ? null : index;
    this.setState({sci: sci, scf: field});
  }

  onDeleteItem(e, id, index) {
    if (id > 0) {
      this.props.deleteItem({type:"order-resolutions",id: id,index: index})
      .then(()=>{
        this.props.purgeItem({type:"order-resolutions", id: id, index: index});
      });
    }
    else {
      this.props.purgeItem({type:"order-resolutions", index: index});
    }
    this.setState({sci: null, notes: {}});
  }

  onDuplicateItem(e, index) {
    this.setState({sci: null, notes: {}});
    let data = deepCopy(dot(this.props.resolutions,[index,"data"]) || DEFAULT_RESOLUTION_DATA);
    delete data.id;
    delete data.updatedAt;
    delete data.deductAmount;
    data.createdAt  = Date.srvtify(data.createdAt,{utc:true});
    data.deadlineAt  = Date.srvtify(data.deadlineAt,{utc:true});
    data.receivedAt  = Date.srvtify(data.receivedAt,{utc:true});
    data.processedAt = Date.srvtify(data.processedAt,{utc:true});
    data.completedAt = Date.srvtify(data.completedAt,{utc:true});
    this.props.startDraftItem({
      type:"order-resolutions",
      index: index,
      insert: true,
      data: data
    });
    // Quick hack to save the item as soon as it is duplicated
    setTimeout(()=>{ this.saveField("reason", data.reason, index); },50);
  }

  saveCompletedField(value, index) {
    this.softEditField("completedAt", value, index);
    let id = dot(this.props.resolutions,[index,"data","id"]);
    let data = deepCopy(dot(this.props.resolutions,[index,"data"]) || {});
    let draft = {sets:{},clears:{},data:data};

    // Check if setting or clearing
    if (value && value.length > 0) {
      let date = Date.parseYMDStr(value);
      draft.sets.completedAt = date.srvstr({utc:true})
      data.completedAt = draft.sets.completedAt;
    }
    else {
      draft.clears.completedAt = true;
      delete data.completedAt;
    }
    // Push the changes
    this.props.pushItem({
      type: "order-resolutions", id: id, index: index,
      docs: { orderResolution: draft },
      query: {links: "order,item,newOrder,newItem"}
    });
  }

  saveCreatedField(value, oldValue, index) {
    console.log("saveCreatedField", {value, oldValue, index});
    if (!value && value == oldValue) { return; }

    // Parse the value to server string
    value = Date.parseYMDStr(value).srvstr({utc:true});
    this.softEditField("createdAt", value, index);

    let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]) || {});
    draft.val("createdAt",value);

    // Push the changes
    this.props.pushItem({
      type: "order-resolutions", id: draft.val("id"), index: index,
      docs: { orderResolution: draft.updates },
      query: {links: "order,item,newOrder,newItem"}
    });
  }

  onCreatedAt(index) {
    let draft = Draft.deep(dot(this.props.resolutions,[index,"data"]) || {});
    let createdAt = Date.utcTransDateInput(draft.val("createdAt"));
    this.props.openPopup({
      name: "Form",
      props: {
        title: "Change Created Date",
        onRender: (popup) =>{
          return (<React.Fragment>
            <Input name="date" type="date" label="Created At" value={createdAt}/>
          </React.Fragment>)
        },
        okLabel: "Save",
        onOk: (data, popup)=>{
          this.saveCreatedField(data.date, createdAt, index);
          this.props.closePopup();
        }
      }
    });
  }

  onTogglePin(index) {
    this.setState({pin:index});
  }

  render() {
    let props = this.props;
    let resolutionsList = props.resolutionsList;
    let resolutions = props.resolutions;
    let view = this.state.view || USER_VIEWS[0].value;
    let download = props.resolutionsDownload;

    let header = <tr>
      <th className="Controls"><FontAwesomeIcon className="ControlIcon" icon={["fal","plus"]} onClick={(e)=>{this.onAdd(e)}}/></th>
      <th className="Created">Created</th>
      <th className="RefName">Customer Name</th>
      <th className="Origin">Origin</th>
      <th className="Order">Original Order</th>
      <th className="Product">Original Product</th>
      <th className="Quantity">Quantity</th>
      <th className="Action">Action</th>
      <th className="NewProduct">New Product</th>
      <th className="NewQuantity">New Quantity</th>

      <th className="FeeAmount">Product $</th>
      <th className="ShipAmount">Ship $</th>
      <th className="Reason">Reason</th>
      <th className="Description">Description</th>
      <th className="Priority">Priority</th>
      <th className="NewOrder">New Order</th>
      <th className="State">Status</th>
      <th className="Completed">Completed</th>

      <th className="Notes">Notes</th>
    </tr>;

    const renderItem = (item, index)=>{
      let data = item.data;
      let pin = data.id ? data.id : `${index}i`;
      return <tr key={`item${index}-${data.id || "0"}-${data.version}`}
        data-deleted={data.deleted}
        data-working={STATUS_WORKING[item.status]}
        data-creating={data.id == null}
        data-pinned={this.state.pin == pin}
        onClick={()=>{this.onTogglePin(pin)}}>
        <td className="Controls">
          {this.genControlsDiv(item,index)}
        </td>
        <td className="Created" onClick={()=>this.onCreatedAt(index)}>
          <DateVal>{data.createdAt}</DateVal>
        </td>
        <td className="RefName">
          <BareInput defaultValue={ data.refName } onBlur={(e)=>{this.saveTextField("refName", e.target.value, data.refName, index)}}/>
        </td>
        <td className="Origin">
          <select defaultValue={data.origin} onChange={(e)=>{this.saveField("origin",e.target.value,index)}} data-hype={data.origin || 'other'}>
            {RESOLUTION_ORIGINS.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="Order">
          {this.genOrderDiv(data.order, index, "orderId")}
        </td>
        <td className="Product">
          {this.genProductDiv(data.item, index, "itemId", data.order)}
        </td>
        <td className="Quantity">
          <BareInput type="number" defaultValue={ data.quantity } onBlur={(e)=>{this.saveTextField("quantity", e.target.value, data.quantity, index)}}/>
        </td>
        <td className="Action">
          <select defaultValue={data.action} onChange={(e)=>{this.saveField("action",e.target.value,index)}} data-hype={data.action || 'unknown'}>
            {RESOLUTION_ACTIONS.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="NewProduct">
          {this.genProductDiv(data.newItem, index, "newItemId", data.order)}
        </td>
        <td className="NewQuantity">
          <BareInput type="number" defaultValue={ data.newQuantity } onBlur={(e)=>{this.saveTextField("newQuantity", e.target.value, data.newQuantity, index)}}/>
        </td>
        <td className="FeeAmount">
          <BareInput type="number"  defaultValue={ money(data.feeAmount) } onBlur={(e)=>{this.saveTextField("feeAmount", e.target.value, data.feeAmount, index)}}/>
        </td>
        <td className="ShipAmount">
          <BareInput type="number"  defaultValue={ money(data.shipAmount) } onBlur={(e)=>{this.saveTextField("shipAmount", e.target.value, data.shipAmount, index)}}/>
        </td>
        <td className="Reason">
          <div data-hype={data.reason || 'undefined'}>{ RESOLUTION_REASONS_MAP[data.reason] }</div>
        </td>
        <td className="Description">
          <BareInput list="resolution-descriptions" defaultValue={ data.description } onChange={(e)=>{this.saveDescriptionField(e.target.value, data.description, index)}}/>
        </td>
        <td className="Priority">
          <select defaultValue={data.priority} onChange={(e)=>{this.saveField("priority",e.target.value,index)}} data-hype={data.priority || 'undefined'}>
            {RESOLUTION_PRIORITIES.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="NewOrder">
          { this.genNewOrderDiv(item, index) }
        </td>
        <td className="State">
          <select defaultValue={data.status} onChange={(e)=>{this.saveStatusField(e.target.value,index)}} data-hype={data.status || 'undefined'}>
            {RESOLUTION_STATES.map((v,i)=>{return <option key={`option${i}`} value={v.value}>{v.name}</option>})}
          </select>
        </td>
        <td className="Completed">
          <DateVal>{data.completedAt}</DateVal>
        </td>
        <td className="Notes">
          {this.genNotesDiv(item, index)}
        </td>
      </tr>
    };

    // Add button
    const addButton = <RectButton theme="blue" onClick={(evt,history)=>{this.onAdd(evt, history)}}>Add Resolution</RectButton>;

    // Get the docs
    return (
      <div className="Page SearchPage ResolutionsPage" data-view={view}>
        <PageHeaderOutline title={this.props.title || "Resolutions"} aside={addButton} top={this._backButton()}>
          <select className="ViewSelector" defaultValue={view} onChange={(e)=>{this.onViewChange(e.target.value)}}>
            {USER_VIEWS.map((v,i)=>{return <option key={`view${i}`} value={v.value}>{v.name}</option>})}
          </select>
          <Link theme="blue" to="/zeros/stats"><FontAwesomeIcon icon={["far","chart-line"]} /> Stats</Link>
          <Link theme="blue" to="#" className={ download.status } data-progress={ pcnt(download.count, download.total) + "%"} onClick={()=>{this.onDownload()}}><FontAwesomeIcon icon="download" /> CSV</Link>
          <Link theme="blue" to="#" onClick={()=>{this.onShowLog()}}><FontAwesomeIcon icon={["far","square-list"]} /> Log</Link>
          <Link theme="blue" to="/zeros/kpi"><FontAwesomeIcon icon={["far","chart-line"]} /> KPI</Link>
        </PageHeaderOutline>
        {this.renderMetrics()}
        <Section ref={this.setSearchRef} className="SearchSection" expanded={this.state.expanded}>
          <PaddedArea className="QuerySubsection">
            <QueryLine type="order-resolutions" throttle={true} onExpand={(evt, history)=>{this.onExpand(evt, history)}} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QuerySummaryLine type="order-resolutions" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
            <QueryOptionsPresentation className="Settings" type="order-resolutions" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
          </PaddedArea>
        </Section>
        <table ref={this.setResultsTableRef} data-sticky={true} className="Card StickyHeader Results" animate={resolutionsList.status}>
          <thead>
            {header}
          </thead>
          <tbody>
          {resolutions.map(renderItem)}
          </tbody>
        </table>
        <QueryPaginatePresentation type="order-resolutions" throttle={true} onConfigChange={(config, throttle)=>{this.onConfigChange(config, throttle)}}/>
        <datalist id="resolution-descriptions">
          {RESOLUTION_DESCRIPTIONS.map((rd,i)=>{
            let reason = RESOLUTION_REASONS_MAP[rd.reason];
            let action = RESOLUTION_ACTIONS_MAP[rd.action];
            let subval = reason != action && action != undefined ? `${reason} | ${action}` : reason;
            return <option key={`option${i}`} value={rd.value}>{subval}</option>;
          })}
        </datalist>
      </div>
    );
  }


}

const mapState = (state, props) => {
  return {
    app: state.app,
    query: dot(state.inventory,"order-resolutions.list.query") || {},
    resolutionsList: dot(state.inventory,"order-resolutions.list") || {},
    resolutions: dot(state.inventory,"order-resolutions.list.docs") || [],
    metricsList: dot(state.inventory,"order-resolutions-status-metrics.list") || {},
    user: dot(state,"session.user"),
    resolutionsDownload: dot(state.inventory,"order-resolutions-download.list") || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    purgeDraftItem: opts => dispatch(purgeDraftItem(opts)),
    meldItems: opts => dispatch(meldItems(opts)),
    meldDownload: opts => dispatch(meldDownload(opts)),
    meldCount: opts => dispatch(meldCount(opts)),
    setItemQuery: opts => dispatch(setItemQuery(opts)),
    setItemField: opts => dispatch(setItemField(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    loadItem: opts => dispatch(loadItem(opts)),
    pushItem: opts => dispatch(pushItem(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    purgeItem: opts => dispatch(purgeItem(opts)),
    closePopup: opts => dispatch(closePopup(opts)),
    setAppField: (f,v)=> dispatch(setAppField(f,v)),
    pushAppNotification: (m,opts) => dispatch(pushAppNotification(m,opts)),
  }
};

export default connect(
  mapState,
  mapDispatch
)(ResolutionsPage)
