import React from 'react';
import { mapk2k } from '../../lib/obj';
import * as FILTERS from '../misc/QueryFilters';

export const RETURN_STATES = [
  { value:"initiated",    name: "Initiated",    desc: "The return has been initiated"},
  { value:"received",     name: "Received",     desc: "Item has been received and is being processed"},
  { value:"processed",    name: "Processed",    desc: "Item has been received and has been processed"},
  { value:"complete",     name: "Complete",     desc: "The return is complete"}
];

export const RETURN_REASONS = [
  { value:"unknown",            name: "Unknown",          desc: "Unknown reason"},
  { value:"damage",             name: "Damage",           desc: "Product was broken or damaged"},
  { value:"defective",          name: "Defective",        desc: "Product has defects"},
  { value:"description",        name: "Description",      desc: "Description did not match product"},
  { value:"design",             name: "Design",           desc: "Did not like design"},
  { value:"duplicate",          name: "Duplicate",        desc: "Duplicate item"},
  { value:"exchange",           name: "Exchange",         desc: "Product is being exchanged for another"},
  { value:"expectations",       name: "Expectations",     desc: "Not what customer expected"},
  { value:"extra",              name: "Extra Item",       desc: "Customer received extra item"},
  { value:"functionality",      name: "Functionality",    desc: "Did not function as expected"},
  { value:"gift",               name: "Gift",             desc: "Unwanted gift"},
  { value:"incompatible",       name: "Incompatible",     desc: "Product does not work for customer use case"},
  { value:"mistake",            name: "Customer Mistake", desc: "Customer mistake or duplicate order"},
  { value:"need",               name: "Not Needed",       desc: "Did not meet customer's needs"},
  { value:"price",              name: "Price",            desc: "Too expensive or cheaper alternatives"},
  { value:"quality",            name: "Quality",          desc: "Bad quality"},
  { value:"remorse",            name: "Remorse",          desc: "Customer change of heart"},
  { value:"replace",            name: "Replace",          desc: "Product is being replaced for another"},
  { value:"support",            name: "Support Mistake",  desc: "Product is being replaced for another"},
  { value:"wrong",              name: "Shipping Mistake", desc: "Wrong product shipped to customer"},
  { value:"timeliness",         name: "Timeliness",       desc: "Took too long to deliver"},
  { value:"undeliverable",      name: "Undeliverable",    desc: "Return to sender, rejected by customs, invalid address, etc"},
  { value:"wardrobing",         name: "Wardrobing",       desc: "Customer was just trying out the product"},
  { value:"other",              name: "Other",            desc: "Other reasons"}
];

export const RETURN_DESCRIPTIONS = [

  { value:"N/A",                            reason: "unknown"},

  { value:"Missing parts or components",    reason: "assembly"},
  { value:"Wrong parts or components",      reason: "assembly"},

  { value:"Damage during shipping",         reason: "damage"},

  { value:"Tesla Charger Issues",           reason: "defective"},
  { value:"Cosmetic defect",                reason: "defective"},
  { value:"3M adhesive issue",              reason: "defective"},
  { value:"Wallet fingerloop issues",       reason: "defective"},
  { value:"Wallet peeling",                 reason: "defective"},
  { value:"Slow charging",                  reason: "defective"},
  { value:"Noisy charging",                 reason: "defective"},
  { value:"Fit issue",                      reason: "defective"},
  { value:"Case loose on the side",         reason: "defective"},

  { value:"Case too slippery",              reason: "design"},
  { value:"No space in vehicle",            reason: "design"},
  { value:"Too bulky",                      reason: "design"},
  { value:"Wallet too tight",               reason: "design"},
  { value:"Protection - case",              reason: "design"},
  { value:"Too Heavy",                      reason: "design"},
  { value:"Fingerprints",                   reason: "design"},
  { value:"Bubbling",                       reason: "design"},
  { value:"Keys Did not fit",               reason: "design"},
  { value:"Unhappy with design",            reason: "design"},
  { value:"Unhappy with color",             reason: "design"},
  { value:"Case too thin",                  reason: "design"},
  { value:"Case too thick",                 reason: "design"},
  { value:"Parts are fragile",              reason: "design"},
  { value:"Parts break during install",     reason: "design"},
  { value:"Difficult to install",           reason: "design"},
  { value:"Sensitive buttons",              reason: "design"},
  { value:"SP bubbling",                    reason: "design"},
  { value:"Wallet obstructing camera view", reason: "design"},
  { value:"Obstruction",                    reason: "design"},
  { value:"Elite accessory part broken",    reason: "design"},

  { value:"Received original order",        reason: "duplicate"},

  { value:"Exchange",                       reason: "exchange"},
  { value:"Ordered wrong product",          reason: "exchange"},
  { value:"Unhappy with product",           reason: "exchange"},
  { value:"Received wrong item",            reason: "exchange"},
  { value:"Did not fit needs",              reason: "exchange"},

  { value:"Did not meet expectations",      reason: "expectations"},
  { value:"Did not work as advertised",     reason: "expectations"},

  { value:"Magsafe compatibility",          reason: "functionality"},
  { value:"Weak magnets - charger",         reason: "functionality"},
  { value:"Kickstand feature didn't work",  reason: "functionality"},
  { value:"Weak magnets - wallet",          reason: "functionality"},
  { value:"Weak hold - 3rd party case",     reason: "functionality"},
  { value:"Weak hold - 3rd party charger",  reason: "functionality"},
  { value:"Weak hold - 3rd party mount",    reason: "functionality"},
  { value:"Weak hold - MagStick",           reason: "functionality"},
  { value:"Weak magnets - case",            reason: "functionality"},
  { value:"Slow/Intermittent charging",     reason: "functionality"},
  { value:"Not charging",                   reason: "functionality"},
  { value:"Compass issues",                 reason: "functionality"},
  { value:"S-Pen issues",                   reason: "functionality"},
  { value:"Case protection",                reason: "functionality"},
  { value:"Vibration issue",                reason: "functionality"},
  { value:"Parts fail or fall off",         reason: "functionality"},

  { value:"Phone overheating",              reason: "functionality"},
  { value:"Charger overheating",            reason: "functionality"},
  { value:"NFC payments not working",       reason: "functionality"},
  { value:"Telescoping magnet issue",       reason: "functionality"},

  { value:"3rd party charger",              reason: "incompatible"},
  { value:"3rd party case",                 reason: "incompatible"},
  { value:"3rd party mount",                reason: "incompatible"},
  { value:"3rd party SP",                   reason: "incompatible"},
  { value:"3rd party wallet",               reason: "incompatible"},
  { value:"MagSafe compatibility Issue",    reason: "incompatible"},
  { value:"Samsung watch",                  reason: "incompatible"},
  { value:"Customer accessory incompatible",reason: "incompatible"},

  { value:"Duplicate order",                reason: "mistake"},
  { value:"Ordered wrong item",             reason: "mistake"},
  { value:"Ordered wrong color",            reason: "mistake"},
  { value:"Accidental return",              reason: "mistake"},

  { value:"Did not fit customer's needs",   reason: "need"},
  { value:"No longer needed",               reason: "need"},

  { value:"Unhappy with Quality",           reason: "quality"},
  { value:"Back of the case ripped",        reason: "quality"},
  { value:"Pinky pillow ripped",            reason: "quality"},
  { value:"Discoloration",                  reason: "quality"},

  { value:"Replace",                        reason: "replace"},

  { value:"RS-Unsolved",                    reason: "undeliverable"},
  { value:"RS-Solved",                      reason: "undeliverable"},
  { value:"RS-USA",                         reason: "undeliverable"},
  { value:"RS-Intl",                        reason: "undeliverable"},
  { value:"DNR FedEx",                      reason: "undeliverable"},
  { value:"DNR USPS",                       reason: "undeliverable"},


  { value:"Extra item",                     reason: "wrong"}, // Shipping Mistake
  { value:"Wrong item",                     reason: "wrong"},
  { value:"Missing item",                   reason: "wrong"},
  { value:"Wrong shipping method",          reason: "wrong"},

  { value:"Support mistake",                reason: "support"},
];


export const RETURN_FULFILLMENT = [
  { value:"initiated",      name: "Initiated",        desc: "The return has ben registered and is now initiated"},
  { value:"shipped",        name: "Shipped",          desc: "Packaged has been shipped"},
  { value:"held",           name: "Held",             desc: "The package is being held or is stuck in transit"},
  { value:"lost",           name: "Lost",             desc: "The package has been lost in transit"},
  { value:"received",       name: "Received",         desc: "The package has been received"}
];

export const RETURN_CARRIERS = [
  { value:"unknown",    name: "Unknown",    desc: "Unknown carrier"},
  { value:"fedex",      name: "FedEx",      desc: "FedEx Service"},
  { value:"usps",       name: "USPS",       desc: "United States Postal Service"},
  { value:"ups",        name: "UPS",        desc: "United Parcel Service"},
  { value:"dhl",        name: "DHL",        desc: "Dalsey, Hillblom and Lynn Service"},
  { value:"other",      name: "Other",      desc: "Other or unknown"}
];

export const RETURN_ORIGINS = [
  { value:"shopify",    name: "Shopify",    desc: "Shopify"},
  { value:"amazon",     name: "Amazon",     desc: "Amzon"},
  { value:"other",      name: "Other",      desc: "Other or unknown"}
];

export const RETURN_RESTOCKED = [
  { value:0,  name: "No",      desc: "The item is not restocked"},
  { value:1,  name: "Yes",     desc: "The item has been restocked"}
];


export const RETURN_RESOLUTIONS = [
  { value:"pending",            name: "Pending",          desc: "Resolution is not yet known"},
  { value:"on-time",            name: "On Time",          desc: "Package was received on time"},
  { value:"late",               name: "Late",             desc: "Package was received after deadline"},
  { value:"decline",            name: "Decline",          desc: "Decline this return"},
  { value:"refund",             name: "Refund",           desc: "Refund the customer for the return"},
  { value:"partial-refund",     name: "Partial Refund",   desc: "Refund the customer for part of the return"},
  { value:"credit",             name: "Credit",           desc: "Give customer credit for the return"},
  { value:"partial-credit",     name: "Partial Credit",   desc: "Give customer credit for part of the return"},
  { value:"replace",            name: "Replace",          desc: "Give the customer a product replacement"},
  { value:"exchange",           name: "Exchange",         desc: "Exchange the returned product for a different product"},
  { value:"disregard",          name: "Disregard",        desc: "No resolution needed"},
  { value:"expired",            name: "Expired",          desc: "Return has expired due to inactivity"},
  { value:"other",              name: "Other",            desc: "Other resolution"}
];

export const RETURN_SEARCH = {
  type: "order-returns",
  links: ["order","item","completedBy"],
  limit: 50,
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["mainOrder.email","mainOrder.name","refName","item.sku"],
    keymap: {
      id: {field:"id", op:"eq"},
      ref: "refName",
      name: "refName",
      order:"mainOrder.name",
      sku: "item.sku",
      email: "mainOrder.email",
      product: "item.name",
      reason: "reason",
      fulfillment: "fulfillmentStatus",
      resolution: "resolution",
      description: "description",
      agent: "completedBy.email"
    }
  },
  filters: [
    {
      label: "Status",
      type: "check",
      field: "status",
      options: RETURN_STATES
    },
    {
      label: "Reason",
      type: "check",
      field: "reason",
      options: RETURN_REASONS
    },
    // {
    //   label: "Fulfillment",
    //   type: "check",
    //   field: "fulfillmentStatus",
    //   options: RETURN_FULFILLMENT
    // },
    {
      label: "Resolution",
      type: "check",
      field: "resolution",
      options: RETURN_RESOLUTIONS
    },
    {
      label: "Restocked",
      type: "check",
      field: "restocked",
      options: RETURN_RESTOCKED
    },
    {
      label: "Origin",
      type: "check",
      field: "origin",
      options: RETURN_ORIGINS
    },
    {
      label: "Created",
      type: "daterange",
      radio: true,
      field: "createdAt",
      options: FILTERS.DATERANGE_OPTIONS
    },
    {
      label: "Completed",
      type: "daterange",
      radio: true,
      field: "completedAt",
      options: FILTERS.DATERANGE_OPTIONS
    }
  ],
  sort: [
    { field: "createdAt",       name: "Created (Oldest to Newest)",    direction:"asc"},
    { field: "createdAt",       name: "Created (Newest to Oldest)",    direction:"desc",  selected: true},
    { field: "updatedAt",       name: "Updated (Oldest to Newest)",    direction:"asc"},
    { field: "updatedAt",       name: "Updated (Newest to Oldest)",    direction:"desc"},
    { field: "receivedAt",      name: "Received (Oldest to Newest)",   direction:"asc"},
    { field: "receivedAt",      name: "Received (Newest to Oldest)",   direction:"desc"},
    { field: "processedAt",     name: "Processed (Oldest to Newest)",  direction:"asc"},
    { field: "processedAt",     name: "Processed (Newest to Oldest)",  direction:"desc"},
    { field: "completedAt",     name: "Completed (Oldest to Newest)",  direction:"asc"},
    { field: "completedAt",     name: "Completed (Newest to Oldest)",  direction:"desc"},
  ]
}

// Mainly used for stats
export const RETURN_BASE_SEARCH = {
  type: "order-returns",
  links: ["item"],
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["item.name","item.sku", "description","reason",],
    keymap: { // TODO - Fernando Mora: Implement this functionality
      sku: "item.sku",
      email: "mainOrder.email",
      product: "item.name",
      reason: "reason",
      fulfillment: "fulfillmentStatus",
      resolution: "resolution",
      description: "description"
    }
  },
  filters: [
    {
      label: "Reason",
      type: "check",
      field: "reason",
      options: RETURN_REASONS
    },
    // {
    //   label: "Fulfillment",
    //   type: "check",
    //   field: "fulfillmentStatus",
    //   options: RETURN_FULFILLMENT
    // },
    {
      label: "Restocked",
      type: "check",
      field: "restocked",
      options: RETURN_RESTOCKED
    },
    {
      label: "Status",
      type: "check",
      field: "status",
      options: RETURN_STATES
    },
    {
      label: "Resolution",
      type: "check",
      field: "resolution",
      options: RETURN_RESOLUTIONS
    },
    {
      label: "Origin",
      type: "check",
      field: "origin",
      options: RETURN_ORIGINS
    },
    {
      label: "Created",
      type: "daterange",
      radio: true,
      field: "createdAt",
      options: FILTERS.DATERANGE_OPTIONS
    },
    {
      label: "Completed",
      type: "daterange",
      radio: true,
      field: "completedAt",
      options: FILTERS.DATERANGE_OPTIONS
    }
  ],
  where:[]
}

export const RETURN_DESCRIPTION_SEARCH = {
  type: "order-returns",
  term: {
    label: "Search",
    placeholder: "Search",
    fields: ["description"]
  },
  fields: "description",
  calcs: "count.id",
  group: "description",
  limit: 25
}

export const RETURN_STATES_MAP = mapk2k(RETURN_STATES,"value","name");
export const RETURN_REASONS_MAP = mapk2k(RETURN_REASONS,"value","name");
export const RETURN_FULFILLMENT_MAP = mapk2k(RETURN_FULFILLMENT,"value","name");
export const RETURN_CARRIERS_MAP = mapk2k(RETURN_CARRIERS,"value","name");
export const RETURN_RESTOCKED_MAP = mapk2k(RETURN_RESTOCKED,"value","name");
export const RETURN_RESOLUTIONS_MAP = mapk2k(RETURN_RESOLUTIONS,"value","name");
export const RETURN_DESCRIPTION_REASONS_MAP = mapk2k(RETURN_DESCRIPTIONS,"value","reason");
